import React from 'react'

import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, ColumnSeries, DataLabel, LineSeries } from '@syncfusion/ej2-react-charts';
export const VerticalBarChart = (props) => {
    const marker = { dataLabel: { visible: true, position: 'Middle', format: 'n2' } };
    const primaryxAxis = { valueType: 'Category' };
    const legendSettings = { visible: false, position: 'Top' };
    return (
        <>
            <ChartComponent legendSettings={legendSettings} primaryXAxis={primaryxAxis} tooltip={{ enable: true }} >
                <Inject services={[ColumnSeries, Legend, LineSeries, Tooltip, Category, DataLabel]} />
                <SeriesCollectionDirective>
                    <SeriesDirective pointColorMapping="color" dataSource={props.dataSource} name={props.name} xName='x' yName='y' type='Column' marker={marker} />
                </SeriesCollectionDirective>
            </ChartComponent>

        </>
    )
}