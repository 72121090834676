import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const TableComponent = (props) => {
    const rows = props.dataSource;
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Country</TableCell>
                            <TableCell align="center">Leads</TableCell>
                            <TableCell align="center">Operations</TableCell>
                            <TableCell align="center">Conversions %</TableCell>
                            <TableCell align="center">Sales</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.country}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.country}
                                </TableCell>
                                <TableCell align="center">{row.leadCount}</TableCell>
                                <TableCell align="center">{row.operationCount}</TableCell>
                                <TableCell align="center">{parseFloat(row.conversion.replace(',', '.')).toFixed(2)}</TableCell>
                                <TableCell align="center">{row.salesCount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
