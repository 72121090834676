import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TextField, Box, MenuItem, Button } from '@mui/material';
import './offertopdf.css';
import configData from "../../../src/config.json";
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SaveIcon from '@mui/icons-material/Save';
export const OfferToPdf = () => {
    const [productAmount, setProductAmount] = useState({});
    const [productCurrencyId, setProductCurrencyId] = useState({});
    const [productId, setProductId] = useState({});
    //const [templateId, setTemplateId] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const Alert = React.forwardRef(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    }
    const ArrangeErrorMessage = (e) => {
        setErrorMessage(e);
        setOpenSnackBar(true);
    }
    const [errorMessage, setErrorMessage] = useState('')
    const [offerToPdfData, setOfferToPdfData] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const [leadproducts, setLeadProducts] = useState([]);
    const ReturnIdentity = () => {
        let Token = "";
        let FirmId = "";
        let UserId = "";
        let OfferToPdfId = "";
        // let Token = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxNjgiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiI0IiwidW5pcXVlX25hbWUiOiJzYWxlcyBzYWxlcyIsInJvbGUiOiJTYWxlc01hbmFnZXIiLCJuYmYiOjE3MTQ2NDc0OTksImV4cCI6MTcxNTA3OTQ5OSwiaWF0IjoxNzE0NjQ3NDk5fQ.pSIB5wV-yYIBevol0zihKLmkxcQLVW_Ph7OlqR7-o0Tnr0mxDDa_QhlbX14l6bVGiYeVNAiiEjleEL_uqwRnBg";
        // let FirmId = 4;
        // let UserId = 168;
        // let OfferToPdfId = 34;
        let url = window.location.search.substring(1);
        let qArray = url.split('&');
        for (var i = 0; i < qArray.length; i++) {
            var pArr = qArray[i].split('=');
            if (pArr[0] == "token")
                Token = pArr[1];
            if (pArr[0] == "offerToPdfId")
                OfferToPdfId = pArr[1];
            if (pArr[0] == "firmId")
                FirmId = pArr[1];
            if (pArr[0] == "userId")
                UserId = pArr[1];
        }
        let Identity = {
            token: Token,
            firmId: FirmId,
            userId: UserId,
            offerToPdfId: OfferToPdfId

        };
        return Identity;
    }
    const handleUpdateOfferToPdfFormSubmit = (event) => {

    }
    const FillLeadProductsSelectBox = (productId) => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.OFFERTOPDF_URL + '/offertopdfproduct/getoffertopdfproductlist?leadProductId=' + productId,
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                let LeadProductsSelectBoxArr = [];
                for (let i = 0; i < response.data.data.length; i++) {
                    let LeadProductObj = {};
                    LeadProductObj.id = response.data.data[i].id;
                    LeadProductObj.name = response.data.data[i].text;
                    LeadProductsSelectBoxArr.push(LeadProductObj);
                }
                setLeadProducts(LeadProductsSelectBoxArr);
            })
    }

    const FillCurrencies = () => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.OFFERTOPDF_URL + '/currency/getcurrenciesselectbox',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setCurrencies(response.data);
            })
    }

    const Initdata = () => {
        const token = ReturnIdentity().token;
        const offerToPdfId = ReturnIdentity().offerToPdfId;
        const instance = axios.create({
            baseURL: configData.OFFERTOPDF_URL + '/offertopdf/GetByIdOfferToPdfAsync',
            params: { offerToPdfId: offerToPdfId },
            headers: { 'Authorization': 'Bearer ' + token }
        });

        instance.get('')
            .then(response => {
                if (response != null && response.data != null && response.data.success == true) {
                    let OfferToPdfTemplateSelectBoxArr = [];
                    for (let i = 0; i < response.data.data.offerToPdfTemplateList.length; i++) {
                        let OfferToPdfTemplateObj = {};
                        OfferToPdfTemplateObj.id = response.data.data.offerToPdfTemplateList[i].offerToPdfTemplateId;
                        OfferToPdfTemplateObj.name = response.data.data.offerToPdfTemplateList[i].name;
                        OfferToPdfTemplateSelectBoxArr.push(OfferToPdfTemplateObj);
                    }
                    setOfferToPdfData({
                        offerToPdfId: response.data.data.id,
                        name: response.data.data.name,
                        offerToPdfSectionList: response.data.data.offerToPdfSectionList,
                        offerToPdfTemplateList: OfferToPdfTemplateSelectBoxArr,
                        offerToPdfCurrencyId: response.data.data.currencyId,
                        offerToPdfProductsId: response.data.data.offerToPdfProductsId,

                    });
                    //  setTemplateId(response.data.data.offerToPdfTemplateList[0].offerToPdfTemplateId)
                    setProductId(response.data.data.productId);
                    ReturnAndSetAmountPriceOfProduct(response.data.data.productId);
                    FillLeadProductsSelectBox(response.data.data.productId);
                }
            })
            .catch(function (error) {

            });
    }

    const ReturnAndSetAmountPriceOfProduct = (productId) => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.OFFERTOPDF_URL + '/leadproducts/GetLeadProductById?productId=' + productId,
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setProductAmount(response.data.data.price);
                setProductCurrencyId(response.data.data.currencyId);
            });
    }

    const UpdateOfferToPdfData = (event) => {
        event.preventDefault();

        if (offerToPdfData.name == "") {
            setIsSuccess(false);
            ArrangeErrorMessage("Please enter offer name..");
        } else {
            var token = ReturnIdentity().token;
            axios.put(configData.OFFERTOPDF_URL + "/offertopdf/UpdateOfferToPdfAsync", {
                Name: offerToPdfData.name,
                OfferToPdfId: offerToPdfData.offerToPdfId,
                OfferToPdfProductsId: offerToPdfData.offerToPdfProductsId,
                ProductId: productId,
                OfferToPdfSectionList: offerToPdfData.offerToPdfSectionList
            }, {
                headers: { "Authorization": `Bearer ${token}` }
            }).then(function (response) {
                if (response.data.success == true) {
                    setIsSuccess(true);
                    ArrangeErrorMessage("Success..");
                } else {

                }
            })
                .catch(function (response) {

                });
        }
    }
    useEffect(() => {
        Initdata();
        FillCurrencies();

    }, []);
    return (
        <>
            <Box component="form" onSubmit={handleUpdateOfferToPdfFormSubmit}    >
                {/* <div className='row'>
                    <div className='col-4'>
                        <label className="LabelStyle">      Template</label>
                    </div>
                    <div className='col-8'>
                        <Select
                            size="small"
                            id="offerToPdfTemplateId"
                            fullWidth
                            value={templateId}
                            error={templateId == undefined ? true : false}
                            errorMessage="Please select template.."
                            helpertext={templateId == undefined ? "Please select template.." : ""}
                            onChange={(e) => {
                                setTemplateId(e.target.value)
                            }}
                            required
                        >
                            {offerToPdfData.offerToPdfTemplateList?.map((data) => (
                                <MenuItem value={data.id} >{data.name} </MenuItem>
                            ))
                            }
                        </Select >
                    </div>
                </div> */}
                <div className='row mt-2'>
                    <div className='col-4'>
                        <label className="LabelStyle">      Name</label>
                    </div>
                    <div className='col-8'>
                        <TextField id="offerToPdfName"
                            onChange={e =>
                                setOfferToPdfData(
                                    prev => ({ ...prev, name: e.target.value })
                                )
                            }
                            value={offerToPdfData?.name}
                            error={offerToPdfData.name == undefined ? true : false}
                            helpertext={offerToPdfData.name == undefined ? "Please enter name.." : ""}
                            placeholder="" type="text" fullWidth variant="outlined" size="small" required />
                    </div>
                </div>
                {<div className='row mt-2'>
                    <div className='col-4'>
                        <label className="LabelStyle">      Product</label>
                    </div>
                    <div className='col-8'>
                        <Select
                            size="small"
                            id="offerToPdfLeadProductId"
                            fullWidth
                            value={productId}
                            error={productId == undefined ? true : false}
                            errorMessage="Please select product.."
                            helpertext={productId == undefined ? "Please select product.." : ""}
                            onChange={(e) => {
                                setProductId(e.target.value)
                                ReturnAndSetAmountPriceOfProduct(e.target.value);
                            }}
                            required
                        >
                            {leadproducts?.map((data) => (
                                <MenuItem value={data.id} >{data.name} </MenuItem>
                            ))
                            }
                        </Select >
                    </div>
                </div>}

                <div className='row mt-2'>
                    <div className='col-4'>
                        <label className="LabelStyle">      Amount</label>
                    </div>
                    <div className='col-5'>
                        <TextField
                            disabled
                            id="offerToPdfAmount"
                            value={productAmount}
                            placeholder="" type="number" fullWidth variant="outlined" size="small" />
                    </div>
                    <div className='col-3'>
                        <Select
                            disabled
                            size="small"
                            id="offerToPdfCurrencyId"
                            fullWidth
                            value={productCurrencyId}
                        >
                            {currencies?.map((data) => (
                                <MenuItem value={data.id} >{data.code} </MenuItem>
                            ))
                            }
                        </Select >
                    </div>


                </div>
                {
                    offerToPdfData.offerToPdfSectionList?.map((data, index) =>

                        < div className='row mt-2' >
                            <div className='col-12'>
                                <div style={{ fontSize: "13", fontWeight: "bold", marginLeft: "7px" }}>Section  {index + 1}/{offerToPdfData.offerToPdfSectionList.length}:</div>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={data?.section}
                                    onReady={editor => {

                                    }}
                                    onChange={(event, editor) => {
                                        const newSections = [...offerToPdfData.offerToPdfSectionList];
                                        newSections[index].section = editor.getData();
                                        offerToPdfData.offerToPdfSectionList = newSections;
                                        setOfferToPdfData(offerToPdfData);
                                    }}
                                    onBlur={(event, editor) => {

                                    }}
                                    onFocus={(event, editor) => {

                                    }}
                                />
                            </div>
                        </div >

                    )
                }

                <div className='row mt-2'>
                    <div className='col-12 mt-2 '>
                        <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackBar}>
                            <Alert    {...isSuccess == true ? { severity: "success" } : { severity: "error" }} sx={{ width: '100%', marginBottom: '50px' }} onClose={handleCloseSnackBar} >
                                {errorMessage}
                            </Alert>
                        </Snackbar>
                    </div>
                    <div className='col-12 mt-2 PullRight'>

                        <Button variant="contained" startIcon={<SaveIcon className='ButtonIcon' />} onClick={UpdateOfferToPdfData} color="success"  > </Button>
                    </div>
                </div>
            </Box >
        </>
    )
}
