import React from "react";

const OverlayVideoRecording = () => {
  return (
    <div className="overlay_container">
      <div className="loader" ></div>
      <div class="OverlayText" >Please wait.. video is uploading..</div>
    </div>
  );
};

export default OverlayVideoRecording;
