import React from 'react'
import { useFormik } from 'formik';
import { webFormBasicSchema } from '../../schemas/WebFormPageSchema';
import { Card, DialogActions, CardContent, Typography, Button, Box, Grid, TextField, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
import configData from "../../../src/config.json";
import { withTranslation } from "react-i18next";

export const WebFormPage = (props) => {
    const [isArabian, setIsArabian] = useState(false);
    const [isLeadProductEnabled, setIsLeadProductEnabled] = useState(false);
    const [errorResult, setErrorResult] = useState('')
    const [errorResultStyle, setErrorResultStyle] = useState('')
    const onSubmit = async (values, actions) => {
        let url = configData.WEBFORMSERVERAPI_URL + "/webform/newwebform";
        let body = JSON.stringify({
            todo: values.webFormSubjectId,
            firstName: values.firstnameId,
            lastName: values.lastnameId,
            email: values.emailId,
            phoneCode: values.phoneCodeId,
            phoneNumber: values.phonenumberId.toString(),
            message: values.messageId,
            guid: firmguid,
            leadProductId: values.leadProductId
        });
        var authOptions = {
            method: "post",
            url: url,
            data: body,
            headers: {
                "Content-Type": "application/json",
            },
            json: true,
        };

        axios(authOptions)
            .then(function (response) {
                if (response.data.success == true) {
                    setErrorResult("Success..");
                    setErrorResultStyle("blue");
                    document.getElementById("WebFormId").reset();
                } else {
                    setErrorResult(response.data.message);
                    setErrorResultStyle("red");
                }
            })
            .catch(function (response) {
                if (response.data) {
                    setErrorResult(response.data.message);
                    setErrorResultStyle("red");
                } else {
                    setErrorResult(response.message);
                    setErrorResultStyle("red");
                }
            });
    };
    const { values, errors, isSubmitting, handleChange, handleSubmit } =
        useFormik({
            initialValues: {
                webFormSubjectId: '',
                firstnameId: '',
                lastnameId: '',
                emailId: '',
                messageId: '',
                phoneCodeId: '',
                phonenumberId: ''
            },
            validationSchema: webFormBasicSchema,
            onSubmit,
        });

    const { t } = props;
    const SetLanguage = (language) => {
        props.i18n.changeLanguage(language);
    };
    const [webFormSubjects, setWebFormSubjects] = useState([]);
    const [leadProducts, setLeadProducts] = useState([]);
    const [phoneCodes, setPhoneCodes] = useState([]);
    const [firmguid, setFirmguid] = useState('');
    useEffect(() => {
        GetFirmGuid();
        FillWebFormSubject();
        FillPhoneCodes();
    }, []);

    const GetFirmGuid = () => {
        var url = window.location.search.substring(1);
        var qArray = url.split('&');
        for (var i = 0; i < qArray.length; i++) {
            var pArr = qArray[i].split('=');
            if (pArr[0] == "lang") {
                SetLanguage(pArr[1])
            }
            if (pArr[0] == "firmguid") {
                setFirmguid(pArr[1]);
                FillLeadProducts(pArr[1])
            }
            if (pArr[0] == "p") {
                setIsLeadProductEnabled(pArr[1] == "on" ? true : false);
            }
            if (pArr[0] == "lang") {
                setIsArabian(pArr[1] == "ar" ? true : false);
            }
        }
    }
    const FillPhoneCodes = () => {
        const instance = axios.create({
            baseURL: configData.WEBFORMSERVERAPI_URL + '/phonecodes/returnphonecodes',
        });
        instance.get('')
            .then(response => {
                setPhoneCodes(response.data.data);
            })
    }
    const FillWebFormSubject = () => {
        const instance = axios.create({
            baseURL: configData.WEBFORMSERVERAPI_URL + '/webform/returnwebformsubjects',
        });
        instance.get('')
            .then(response => {
                setWebFormSubjects(response.data.data);
            })
    }
    const FillLeadProducts = (guid) => {
        const instance = axios.create({
            baseURL: configData.WEBFORMSERVERAPI_URL + '/leadproducts/getproducts?guid=' + guid,
        });
        instance.get('')
            .then(response => {
                setLeadProducts(response.data.data);
            })
    }
    return (
        <>
            <div className='WebFormMain'>
                <Card >
                    <CardContent>
                        <Typography variant="h5" component="div"  >
                            <label className="WebFormTitleBig"> <h3>{t("ContactUs")}</h3></label> <br />
                            <label className='WebFormTitleSmall'>{t("ContactUsFillForm")}</label>
                        </Typography>
                        <br />
                        {!isArabian &&
                            <Box component="form" id="WebFormId" onSubmit={handleSubmit} >
                                <div className='row'>
                                    <div className='col-4'  >
                                        <label className="LabelStyle">{t("ThingYouWantToDo")} </label>
                                    </div>
                                    <div className='col-8' >
                                        <TextField
                                            size="small"
                                            id="webFormSubjectId"
                                            select
                                            value={values.webFormSubjectId}
                                            fullWidth
                                            onChange={handleChange("webFormSubjectId")}
                                            className={values.webFormSubjectId && Boolean(errors.webFormSubjectId) ? 'input-error' : ''}
                                        >
                                            {webFormSubjects?.map((data) => (
                                                <MenuItem key={data.id} value={data.id} >{`${t(data.text)}`}</MenuItem>
                                            ))}

                                        </TextField>
                                        {errors.webFormSubjectId && <p className="error"> {`${t("PleaseSelectWebFormSubject")}`}</p>}
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-4'>
                                        <label className="LabelStyle">  {t("Name")}  </label>
                                    </div>
                                    <div className='col-8'>
                                        <TextField id="firstnameId"
                                            onChange={handleChange}
                                            placeholder=""
                                            type="text"
                                            value={values.firstnameId}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            className={errors.firstnameId ? 'input-error' : ''}
                                        />
                                        {errors.firstnameId && <p className="error">{`${t("PleaseEnterName")}`}</p>}
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-4'>
                                        <label className="LabelStyle">  {t("Surname")}   </label>
                                    </div>
                                    <div className='col-8'>
                                        <TextField id="lastnameId"
                                            onChange={handleChange}
                                            placeholder=""
                                            type="text"
                                            value={values.lastnameId}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            className={errors.lastnameId ? 'input-error' : ''}
                                        />
                                        {errors.lastnameId && <p className="error">{`${t("PleaseEnterSurname")}`}</p>}
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-4'>
                                        <label className="LabelStyle">  {t("Email")}  </label>
                                    </div>
                                    <div className='col-8'>
                                        <TextField id="emailId"
                                            onChange={handleChange}
                                            placeholder=""
                                            type="email"
                                            value={values.emailId}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            className={errors.emailId ? 'input-error' : ''}
                                        />
                                        {errors.emailId && <p className="error">{`${t("PleaseEnterEmail")}`}</p>}
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-4'>
                                        <label className="LabelStyle">   {t("PhoneCodePhoneNumber")}     </label>
                                    </div>
                                    <div className='col-3'>
                                        <TextField
                                            size="small"
                                            id="phoneCodeId"
                                            select
                                            fullWidth
                                            onChange={handleChange("phoneCodeId")}
                                            className={values.phoneCodeId && Boolean(errors.phoneCodeId) ? 'input-error' : ''}
                                        >
                                            {phoneCodes?.map((data) => (
                                                <MenuItem key={data.text2} value={data.text2}  >   {data.text} </MenuItem>
                                            ))}
                                        </TextField>
                                        {errors.phoneCodeId && <p className="error"> {`${t("PleaseSelectPhoneCode")}`}</p>}
                                    </div>
                                    <div className='col-5'>
                                        <TextField id="phonenumberId"
                                            placeholder=""
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChange("phonenumberId")}
                                            className={values.phonenumberId && Boolean(errors.phonenumberId) ? 'input-error' : ''}
                                        />
                                        {errors.phonenumberId && <p className="error"> {`${t("PleaseEnterPhoneNumber")}`}</p>}
                                    </div>
                                </div>

                                {isLeadProductEnabled &&
                                    <div className='row mt-2'>
                                        <div className='col-4'>
                                            <label className="LabelStyle">  {t("WhichProductAreYouInterestedIn")}      </label>
                                        </div>
                                        <div className='col-8'>
                                            <TextField
                                                size="small"
                                                id="leadProductId"
                                                select
                                                fullWidth
                                                onChange={handleChange("leadProductId")}
                                                className={values.leadProductId && isLeadProductEnabled && Boolean(errors.leadProductId) ? 'input-error' : ''}
                                            >
                                                {leadProducts?.map((data) => (
                                                    <MenuItem key={data.id} value={data.id}  >   {data.name} </MenuItem>
                                                ))}
                                            </TextField>
                                            {errors.leadProductId && isLeadProductEnabled && <p className="error"> {`${t("PleaseSelectLeadProduct")}`}</p>}
                                        </div>
                                    </div>
                                }
                                <div className='row mt-2'>
                                    <div className='col-4'>
                                        <label className="LabelStyle">  {t("Message")}      </label>
                                    </div>
                                    <div className='col-8'>
                                        <TextField id="messageId"
                                            multiline
                                            rows={3}
                                            onChange={handleChange}
                                            placeholder=""
                                            type="text"
                                            value={values.messageId}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            className={errors.messageId ? 'input-error' : ''}
                                        />
                                        {errors.messageId && <p className="error">{`${t("PleaseEnterMessage")}`}</p>}
                                    </div>
                                </div>

                                <div className="WebFormButtons">
                                    <Grid item={true} md={12}>
                                        <label style={{ color: `${errorResultStyle}` }}>{errorResult}</label>
                                    </Grid>
                                    <Grid item={true} md={12} >
                                        <DialogActions>
                                            <Button type="submit" size="small" startIcon={<SaveIcon />} variant='contained' color="success"  >{t("Send")}</Button>
                                        </DialogActions>
                                    </Grid>
                                </div>
                            </Box>
                        }


                        {isArabian &&
                            <Box component="form" id="WebFormId" onSubmit={handleSubmit} >
                                <div className='row'>
                                    <div className='col-8'>
                                        <TextField
                                            size="small"
                                            id="webFormSubjectId"
                                            select
                                            value={values.webFormSubjectId}
                                            fullWidth
                                            onChange={handleChange("webFormSubjectId")}
                                            className={values.webFormSubjectId && Boolean(errors.webFormSubjectId) ? 'input-error' : ''}
                                        >
                                            {webFormSubjects?.map((data) => (
                                                <MenuItem key={data.id} value={data.id} >{`${t(data.text)}`}</MenuItem>
                                            ))}

                                        </TextField>
                                        {errors.webFormSubjectId && <p className="error"> {`${t("PleaseSelectWebFormSubject")}`}</p>}
                                    </div>
                                    <div className='col-4'>
                                        <label className="LabelStyle">{t("ThingYouWantToDo")} </label>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-8'>
                                        <TextField id="firstnameId"
                                            onChange={handleChange}
                                            placeholder=""
                                            type="text"
                                            value={values.firstnameId}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            className={errors.firstnameId ? 'input-error' : ''}
                                        />
                                        {errors.firstnameId && <p className="error">{`${t("PleaseEnterName")}`}</p>}
                                    </div>
                                    <div className='col-4'>
                                        <label className="LabelStyle">  {t("Name")}  </label>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-8'>
                                        <TextField id="lastnameId"
                                            onChange={handleChange}
                                            placeholder=""
                                            type="text"
                                            value={values.lastnameId}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            className={errors.lastnameId ? 'input-error' : ''}
                                        />
                                        {errors.lastnameId && <p className="error">{`${t("PleaseEnterSurname")}`}</p>}
                                    </div>
                                    <div className='col-4'>
                                        <label className="LabelStyle">  {t("Surname")}   </label>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-8'>
                                        <TextField id="emailId"
                                            onChange={handleChange}
                                            placeholder=""
                                            type="email"
                                            value={values.emailId}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            className={errors.emailId ? 'input-error' : ''}
                                        />
                                        {errors.emailId && <p className="error">{`${t("PleaseEnterEmail")}`}</p>}
                                    </div>
                                    <div className='col-4'>
                                        <label className="LabelStyle">  {t("Email")}  </label>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-5'>
                                        <TextField id="phonenumberId"
                                            placeholder=""
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChange("phonenumberId")}
                                            className={values.phonenumberId && Boolean(errors.phonenumberId) ? 'input-error' : ''}
                                        />
                                        {errors.phonenumberId && <p className="error"> {`${t("PleaseEnterPhoneNumber")}`}</p>}
                                    </div>
                                    <div className='col-3'>
                                        <TextField
                                            size="small"
                                            id="phoneCodeId"
                                            select
                                            fullWidth
                                            onChange={handleChange("phoneCodeId")}
                                            className={values.phoneCodeId && Boolean(errors.phoneCodeId) ? 'input-error' : ''}
                                        >
                                            {phoneCodes?.map((data) => (
                                                <MenuItem key={data.text2} value={data.text2}  >   {data.text} </MenuItem>
                                            ))}
                                        </TextField>
                                        {errors.phoneCodeId && <p className="error"> {`${t("PleaseSelectPhoneCode")}`}</p>}
                                    </div>
                                    <div className='col-4'>
                                        <label className="LabelStyle">   {t("PhoneCodePhoneNumber")}     </label>
                                    </div>
                                </div>

                                {isLeadProductEnabled &&
                                    <div className='row mt-2'>
                                        <div className='col-8'>
                                            <TextField
                                                size="small"
                                                id="leadProductId"
                                                select
                                                fullWidth
                                                onChange={handleChange("leadProductId")}
                                                className={values.leadProductId && isLeadProductEnabled && Boolean(errors.leadProductId) ? 'input-error' : ''}
                                            >
                                                {leadProducts?.map((data) => (
                                                    <MenuItem key={data.id} value={data.id}  >   {data.name} </MenuItem>
                                                ))}
                                            </TextField>
                                            {errors.leadProductId && isLeadProductEnabled && <p className="error"> {`${t("PleaseSelectLeadProduct")}`}</p>}
                                        </div>
                                        <div className='col-4'>
                                            <label className="LabelStyle">  {t("WhichProductAreYouInterestedIn")}      </label>
                                        </div>
                                    </div>
                                }
                                <div className='row mt-2'>
                                    <div className='col-8'>
                                        <TextField id="messageId"
                                            multiline
                                            rows={3}
                                            onChange={handleChange}
                                            placeholder=""
                                            type="text"
                                            value={values.messageId}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            className={errors.messageId ? 'input-error' : ''}
                                        />
                                        {errors.messageId && <p className="error">{`${t("PleaseEnterMessage")}`}</p>}
                                    </div>
                                    <div className='col-4'>
                                        <label className="LabelStyle">  {t("Message")}      </label>
                                    </div>
                                </div>

                                <div className="WebFormButtons">
                                    <Grid item={true} md={12}>
                                        <label style={{ color: `${errorResultStyle}` }}>{errorResult}</label>
                                    </Grid>
                                    <Grid item={true} md={12} >
                                        <DialogActions>
                                            <Button type="submit" size="small" startIcon={<SaveIcon />} variant='contained' color="success"  >{t("Send")}</Button>
                                        </DialogActions>
                                    </Grid>
                                </div>
                            </Box>
                        }
                    </CardContent>
                </Card>
            </div >
        </>
    )
}

export default withTranslation()(WebFormPage);