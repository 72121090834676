import { Grid, Button, TextField, MenuItem, Box } from '@mui/material';
import React from 'react'
import axios from 'axios';
import { forwardRef, useImperativeHandle } from 'react';
import configData from "../../../src/config.json";
export const SchedulerFilterForm = forwardRef((props, ref) => {
    const formatDate = (d) => {
        var dd = d.getDate();
        var mm = d.getMonth() + 1;
        var yyyy = d.getFullYear();
        if (dd < 10) { dd = '0' + dd }
        if (mm < 10) { mm = '0' + mm };
        return d = yyyy + '-' + mm + '-' + dd;
    }
    // const { leadProductsId } = props.useSelector((state) => {
    //     return {
    //         searchTextId: state.filterform.searchTextId
    //     };
    // });
    function FillScheduler(searchText) {
        var schObj = document.getElementById("WeaseSchedulerId").ej2_instances[0];
        var currentViewDates = schObj.getCurrentViewDates();
        var Start = formatDate(currentViewDates[0]);
        var End = formatDate(currentViewDates[currentViewDates.length - 1]);
        let QueryString = "?Start=" + Start + "&End=" + End;
        if (searchText != undefined && searchText != "") {
            QueryString += "&searchTextId=" + searchText;
        }

        // if (leadProductsId.length > 0) {
        //     QueryString += "&leadProductsId="
        //     QueryString += JSON.stringify(leadProductsId);
        // }
        var token = props.ReturnToken;
        axios({
            method: "get",
            url: configData.SCHEDULERSERVERAPI_URL + "/calendar/GetCalendarEvents" + QueryString,
            headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
        })
            .then(function (response) {

                var schObj = document.getElementById("WeaseSchedulerId").ej2_instances[0];
                schObj.eventSettings.dataSource = response.data.calendarEvents;
            })
            .catch(function (response) {

            });
    }


    useImperativeHandle(ref, () => ({
        CallFillScheduler() { FillScheduler(); }
    }));
    const handleSchedulerFilterSubmit = (e) => {
        e.preventDefault();
        FillScheduler();
    }

    return (
        <Box component="form" onSubmit={handleSchedulerFilterSubmit} >
            <Grid container display="flex" spacing={1} >
                <Grid item={true} md={12}> <Box>
                    <label>Search</label>
                    <TextField
                        inputProps={{
                            style: {
                                height: "11px",
                            },
                        }}
                        fullWidth onChange={(e) => { FillScheduler(e.target.value); }} /></Box> </Grid>
                {/* <Grid item={true} md={4}>
                    <Box  >
                        <TextField size="small" style={{ display: "none" }}
                            SelectProps={{
                                multiple: true
                            }} defaultValue={[]}
                            label="Operation Types" select fullWidth onChange={(event) => {
                                // props.dispatch(changeLeadProductsId(event.target.value));
                            }} value={leadProductsId} >
                            {props.leadProducts?.map((data) => (
                                <MenuItem key={data.id} value={data.id}>{data.text}</MenuItem>
                            ))}
                        </TextField>
                    </Box >
                </Grid> */}
                <Grid item={true} md={6}>
                    <Box  >
                    </Box>
                </Grid>
                {/* <Grid style={{ display: "none" }} item={true} md={2}  > <Box display="flex" justifyContent="flex-end"><Button type="submit" size="small" variant='contained' startIcon={<RefreshIcon />}> Refresh Calendar</Button></Box>      </Grid> */}
                {/* <Grid item={true} md={2}  > <Box display="flex" justifyContent="flex-end"><Button onClick={() => props.setDialogAddRecordOpen(true)} color="success" size="small" variant='contained' startIcon={<AddIcon />}> Add Record</Button></Box>      </Grid> */}

            </Grid>
        </Box>
    )
});


export default SchedulerFilterForm;