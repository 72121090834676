const Actions = {
  SET_IS_ROOM_HOST: "SET_IS_ROOM_HOST",
  SET_CONNECT_ONLY_WITH_AUDIO: "SET_CONNECT_ONLY_WITH_AUDIO",
  SET_IDENTITY: "SET_IDENTITY",
  SET_ROOM_ID: "SET_ROOM_ID",
  SET_SHOW_OVERLAY: "SET_SHOW_OVERLAY",
  SET_SHOW_OVERLAY_VIDEO_RECORDING: "SET_SHOW_OVERLAY_VIDEO_RECORDING",
  SET_SOCKET_ID: 'SET_SOCKET_ID',
  SET_PARTICIPANTS: "SET_PARTICIPANTS",
  IS_MEETING_STARTED: "IS_MEETING_STARTED",
  SET_FIRMNAME: "SET_FIRMNAME",
  SET_LEAD_FULLNAME: "SET_LEAD_FULLNAME",

};

export const setIsRoomHost = (isRoomHost) => {
  return {
    type: Actions.SET_IS_ROOM_HOST,
    isRoomHost,
  };
};

export const setConnectOnlyWithAudio = (onlyWithAudio) => {
  return {
    type: Actions.SET_CONNECT_ONLY_WITH_AUDIO,
    onlyWithAudio,
  };
};

export const setIdentity = (identity) => {
  return {
    type: Actions.SET_IDENTITY,
    identity,
  };
};

export const setRoomId = (roomId) => {
  return {
    type: Actions.SET_ROOM_ID,
    roomId,
  };
};

export const setShowOverlay = (showOverlay) => {
  return {
    type: Actions.SET_SHOW_OVERLAY,
    showOverlay,
  };
};

export const setShowOverlayVideoRecording = (showOverlayVideoRecording) => {
  return {
    type: Actions.SET_SHOW_OVERLAY_VIDEO_RECORDING,
    showOverlayVideoRecording,
  };
};

export const setParticipants = (participants) => {
  return {
    type: Actions.SET_PARTICIPANTS,
    participants,
  };
};
export const setSocketId = (socketId) => {
  return {
    type: Actions.SET_SOCKET_ID,
    socketId
  }
}

export const setIsMeetingStarted = (isMeetingStarted) => {
  return {
    type: Actions.IS_MEETING_STARTED,
    isMeetingStarted,
  }
}

export const setFirmName = (firmName) => {
  return {
    type: Actions.SET_FIRMNAME,
    firmName,
  }
}

export const setLeadFullName = (leadFullName) => {
  return {
    type: Actions.SET_LEAD_FULLNAME,
    leadFullName,
  }
}

export default Actions;
