import * as yup from 'yup';
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

export const webFormBasicSchema = yup.object().shape({
    webFormSubjectId: yup
        .string()
        .required(),
    firstnameId: yup
        .string()
        .required(),
    lastnameId: yup
        .string()
        .required(),
    emailId: yup
        .string()
        .email('')
        .required(),
    messageId: yup
        .string()
        .required(),
    phoneCodeId: yup
        .string()
        .required(),
    phonenumberId: yup
        .number()
        .required(),
    leadProductId: yup
        .string().when("isLeadProductEnabled", {
            is: (g) => g === true,
            then: yup.string().required(),
        }),
});


