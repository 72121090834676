import React from 'react'
import { Card, CardContent, Typography } from '@mui/material';
export const BoxWidget = (props) => {
    return (
        <>
            <Card className='CardStyle1'>
                <CardContent >
                    <Typography variant="h6" component="div" className={props.TypographyClassName}>
                        {props.name}
                    </Typography>
                    <Typography variant="body2" component="p" className="WidgetBody" >
                        {props.value} {props.value2}
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}
