import React, { Component, useEffect, useState } from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { Loading } from '../Loading';


const FormatDecimal = (integerim) => {
    integerim = integerim.toString();
    let spt = integerim.replace(",", ".").split(".");
    let para = spt[0];
    let lengthOfKurusPart = 0;
    let Kuruspart = 0;
    if (spt[1] != undefined && spt[1] != null) {
        lengthOfKurusPart = spt[1].toString().length;//10
        Kuruspart = Math.round(spt[1].slice(0, 1) + "." + spt[1].slice(1, lengthOfKurusPart - 1))// 56.57 => 0.57
    }
    let sonuc = "";
    if (para.length <= 3) {
        sonuc = para + "," + Kuruspart;
    }
    else {
        while
            (parseInt(para / 1000) > 0) {
            let tempSonuc = para % 1000;
            while (tempSonuc.toString().length != 3) {
                tempSonuc = "0" + tempSonuc;
            }
            para = parseInt(para / 1000);
            if (sonuc.toString().length == 0) {
                sonuc = tempSonuc;
            }
            else {
                sonuc = tempSonuc + "." + sonuc;
            }
        }
        sonuc = sonuc + "," + Kuruspart;

        if (para.toString().length > 0) {
            sonuc = para + "." + sonuc;
        }
    }
    return sonuc;
}



export const AmChartV4WorldMap = (props) => {

    const InitAmChart = () => {
        let chart = am4core.create("chartdiv", am4maps.MapChart);
        chart.homeZoomLevel = 1;
        chart.events.on("ready", (ev) => {

        });
        chart.geodata = am4geodata_worldLow;
        chart.projection = new am4maps.projections.Miller();
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.exclude = ["AQ"];
        polygonSeries.useGeodata = true;
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}";
        // polygonTemplate.fill = am4core.color("#74B266");
        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = am4core.color("#eac768");

        var labelSeries = chart.series.push(new am4maps.MapImageSeries());
        var labelTemplate = labelSeries.mapImages.template.createChild(am4core.Label);
        labelTemplate.horizontalCenter = "middle";
        labelTemplate.verticalCenter = "middle";
        labelTemplate.fontSize = 4;
        labelTemplate.fill = "#2da8b7";
        labelTemplate.interactionsEnabled = false;
        labelTemplate.nonScaling = false;

        polygonSeries.events.on("inited", function () {
            for (var i = 0; i < props.datasource.length; i++) {
                var polygon = polygonSeries.getPolygonById(props.datasource[i].countryIso2);
                if (polygon) {
                    var label = labelSeries.mapImages.create();
                    var state = polygon.dataItem.dataContext.id.split("-").pop();
                    label.latitude = polygon.visualLatitude;
                    if (props.datasource[i].countryIso2 == "RU") {
                        label.longitude = 100;
                    } else {
                        label.longitude = polygon.visualLongitude;
                    }
                    label.children.getIndex(0).text = props.datasource[i].country + " :L:" + props.datasource[i].leadCount + " C:" + FormatDecimal(props.datasource[i].conversion) + "%";
                }
            }
        });

    }
    InitAmChart();
    useEffect(() => {
        InitAmChart();

    }, [])

    // chart.homeGeoPoint = {
    //     latitude: 52,
    //     longitude: 31
    // };



    return (
        <div>

            <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
        </div>
    )
}
