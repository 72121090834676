import React from "react";

const Overlay = () => {
  return (
    <div className="overlay_container">
      <div className="loader"></div>
      <div class="OverlayText" >Please wait.. camera is getting ready..</div>
    </div>
  );
};

export default Overlay;
