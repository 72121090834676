import io from "socket.io-client";
import { setRoomId, setParticipants, setSocketId, setIsMeetingStarted } from "../store/actions";
import store from "../store/store";
import * as webRTCHandler from "./webRTCHandler";
import configData from "../../src/config.json";
import * as Constants from "../Constants";
import axios from 'axios';
let socket = null;

export const connectWithSocketIOServer = () => {

  var queryparam = window.location.search.substring(1); //get rid of "?" in querystring
  if (queryparam != "") {
    var queryparamarray = queryparam.split('&');

    for (var i = 0; i < queryparamarray.length; i++) {
      var pArr = queryparamarray[i].split('='); //split key and value
      if (pArr[0] == "roomId") {
        Constants.webRtcRoomId = pArr[1];
        store.dispatch(setRoomId(pArr[1]));
      }
    }
  }


  socket = io(configData.WEBRTCNODEJSSERVER_URL);

  socket.on("connect", () => {
    // console.log("successfully connected with socket io server");
    // console.log(socket.id);
    store.dispatch(setSocketId(socket.id));
  });

  socket.on("room-id", (data) => {  ////burası bir kez, agent tarafından room açıldığı zaman tetiklenir......
    // const { roomId } = data;

    if (Constants.token == undefined) {    //bu lead
      ///axios check is  it appropirate
      // axios({
      //   method: "post",
      //   url: configData.WEBRTCSERVERAPI_URL + "/webrtclogs/checkwebrtclogavailabilityforlead",
      //   headers: { 'Content-Type': 'multipart/form-data' },
      //   data: { meetingId: Constants.idFromHyperlink }
      // })
      //   .then(function (response) {
      //     if (response.data.success == false) {
      //       window.location.href = "https://www.wease.io"
      //     }
      //   })
      //   .catch(function (response) {


      //   });
    } else {                    //bu agent
      Constants.meetingId = Constants.webRtcRoomId;
      Constants.isRecordingStarted = false;
      var token = Constants.token;
      axios({
        method: "post",
        url: configData.WEBRTCSERVERAPI_URL + "/webrtclogs/createinitialwebrtclog",
        data: { meetingId: Constants.webRtcRoomId, leadId: Constants.leadId },
        headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      })
        .then(function (response) {

        })
        .catch(function (response) {

        });
    }
  });

  socket.on("room-update", (data) => {
    const { connectedUsers } = data;
    store.dispatch(setParticipants(connectedUsers));
    if (connectedUsers.length > 1) {
      store.dispatch(setIsMeetingStarted(true));
    }
  });

  socket.on("conn-prepare", (data) => {
    const { connUserSocketId } = data;

    if (Constants.isRecordingStarted == false) {
      webRTCHandler.handleRecordButtonPressed(false);   //record start butonuna bas!
      Constants.isRecordingStarted = true;
      store.dispatch(setIsMeetingStarted(true));
    }

    webRTCHandler.prepareNewPeerConnection(connUserSocketId, false);
    // inform the user which just join the room that we have prepared for incoming connection
    socket.emit("conn-init", { connUserSocketId: connUserSocketId });
  });

  socket.on("conn-signal", (data) => {
    webRTCHandler.handleSignalingData(data);
  });

  socket.on("conn-init", (data) => {
    const { connUserSocketId } = data;
    webRTCHandler.prepareNewPeerConnection(connUserSocketId, true);
  });

  socket.on("user-disconnected", (data) => {
    webRTCHandler.removePeerConnection(data);
  });

};

export const createNewRoom = (identity, onlyAudio, leadFullName, firmName, roomId) => {
  // emit an event to server that we would like to create new room
  const data = {
    identity,
    onlyAudio,
    leadFullName,
    firmName,
    roomId
  };
  socket.emit("create-new-room", data);
};

export const joinRoom = (identity, roomId, onlyAudio, leadFullName, firmName) => {
  //emit an event to server that we would to join a room
  const data = {
    roomId,
    identity,
    onlyAudio,
    leadFullName,
    firmName
  };

  socket.emit("join-room", data);
};

export const signalPeerData = (data) => {
  socket.emit("conn-signal", data);
};

