import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { setShowOverlayVideoRecording } from "../../../store/actions";
import { Grid, Button, TextField, Box } from '@mui/material';
import CameraOpen from '@mui/icons-material/LocalSee';
import CameraClosed from '@mui/icons-material/NoPhotography';
import AdjustIcon from '@mui/icons-material/Adjust';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import axios from 'axios';
import LogoutIcon from '@mui/icons-material/Logout';
import ScreenShare from '@mui/icons-material/ScreenShare';
import StopScreenShare from '@mui/icons-material/StopScreenShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import * as webRTCHandler from "../../../utils/webRTCHandler";
import LocalScreenSharingPreview from "./LocalScreenSharingPreview";
import LoginIcon from '@mui/icons-material/Login';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import configData from "../../../../src/config.json";
import { setIsRoomHost, setLeadFullName } from "../../../store/actions";
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import * as Constants from "../../../Constants";
import store from "../../../store/store";
const VideoSection = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setRoomIdFromHyperlink(id) {
      setRoomId(id);
    },
    setFirmNameFromHyperlink(name) {
      setFirmNameForLead(name);
      Constants.firmNameForLead = name;
    }
  }));

  useEffect(() => {
    setHyperlinkId({
      hyperlinkId: configData.WEBRTCPAGE_URL + "/" + props.roomId
    })
  }, []);
  const ConnectButton = () => {
    setIsRoomHost(false);

    let leadFullName = store.getState().leadFullName;
    let firmName = store.getState().firmName;
    webRTCHandler.getLocalPreviewAndInitRoomConnection(
      false,
      "",
      roomId,
      false,
      false,
      firmName,
      leadFullName
    );
  };


  const ConnectAudioOnlyButton = () => {
    setIsRoomHost(false);
    let leadFullName = store.getState().leadFullName;
    let firmName = store.getState().firmName;
    webRTCHandler.getLocalPreviewAndInitRoomConnection(
      false,
      "",
      roomId,
      true,
      false,
      firmName,
      leadFullName
    );
  };

  const Alert = forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };
  const [isLeadCamVisible, setisLeadCamVisible] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [roomId, setRoomId] = useState('')
  const [firmNameForLead, setFirmNameForLead] = useState('')
  const [hyperlinkId, setHyperlinkId] = useState('')
  const connectOnlyWithAudio = false;
  const constraints = {
    audio: false,
    video: true,
  };
  const [isMicMuted, setIsMicMuted] = useState(false);
  const [isLocalVideoDisabled, setIsLocalVideoDisabled] = useState(false);
  const [isRecordOff, setisRecordOff] = useState(false);
  const [isScreenSharingActive, setIsScreenSharingActive] = useState(false);
  const [screenSharingStream, setScreenSharingStream] = useState(null);

  const handleScreenShareToggle = async () => {
    if (!isScreenSharingActive) {
      let stream = null;
      try {
        stream = await navigator.mediaDevices.getDisplayMedia(constraints);
      } catch (err) {
        console.log(
          "error occurred when trying to get an access to screen share stream"
        );
      }
      if (stream) {
        setScreenSharingStream(stream);

        webRTCHandler.toggleScreenShare(isScreenSharingActive, stream);
        setIsScreenSharingActive(true);
        // execute here function to switch the video track which we are sending to other users
      }
    } else {
      webRTCHandler.toggleScreenShare(isScreenSharingActive);
      setIsScreenSharingActive(false);

      // stop screen share stream
      screenSharingStream.getTracks().forEach((t) => t.stop());
      setScreenSharingStream(null);
    }
  };


  const SendSignalForStopRecording = async () => {
    const identity = await ReturnIdentity();
    if (identity.firmId != "" && identity.leadId != "" && identity.userId != "") {

      axios.post(configData.WEBFORMSERVERAPI_URL + "/webrtclogs/endwebrtcvideo", {
        FirmId: identity.firmId,
        LeadId: identity.leadId,
        AgentId: identity.userId
      }, {
        headers: { "Authorization": `Bearer ${identity.token}`, 'Content-Type': 'multipart/form-data' }
      }).then(function (response) {
        if (response.data.success == true) {

        } else {

        }
      })
        .catch(function (response) {

          if (response.data) {

          } else {

          }
        });
    }
  }
  const ReturnIdentity = async () => {

    let Token = "";
    let FirmId = "";
    let UserId = "";
    let LeadId = "";
    let OperationClaimId = "";
    //   var Token = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI1IiwidW5pcXVlX25hbWUiOiJCdXJjdSBCYWxjxLEiLCJyb2xlIjoiQWdlbnQiLCJuYmYiOjE2OTE4NzkwMDMsImV4cCI6MTY5MjMxMTAwMywiaWF0IjoxNjkxODc5MDAzfQ.O7Emc5vv5lkLtUYKeQpdWVTE1IvJhCJXx5DMlbtN7Xa6iGT_2IFRTzm4CToXJxp6HkHUrafxUe_XeRyo_AkWCw";
    // var FirmId = 1;
    // var UserId = 5;
    var url = window.location.search.substring(1); //get rid of "?" in querystring
    var qArray = url.split('&'); //get key-value pairs
    for (var i = 0; i < qArray.length; i++) {
      var pArr = qArray[i].split('='); //split key and value
      if (pArr[0] == "token")
        Token = pArr[1]; //return value
      if (pArr[0] == "firmid")
        FirmId = pArr[1]; //return value
      if (pArr[0] == "userid")
        UserId = pArr[1]; //return value
      if (pArr[0] == "leadid")
        LeadId = pArr[1]; //return value
      if (pArr[0] == "operationclaimid")
        OperationClaimId = pArr[1]; //return value
    }
    var Identity = {
      token: Token,
      firmId: FirmId,
      userId: UserId,
      operationClaimId: OperationClaimId,
      leadId: LeadId
    };
    return Identity;
  }
  const handleRoomDisconnectionForWeaseUser = async () => {
    store.dispatch(setShowOverlayVideoRecording(true));
    await SendSignalForStopRecording();
  };

  const handleRoomDisconnectionForLead = async () => {
    window.location.href = "https://www.wease.io";
  };
  const handleRecordButtonPressed = () => {
    webRTCHandler.handleRecordButtonPressed(isRecordOff);
    setisRecordOff(!isRecordOff);
  };
  const handleMicButtonPressed = () => {
    webRTCHandler.toggleMic(isMicMuted);

    setIsMicMuted(!isMicMuted);
  };
  const handleCameraButtonPressed = () => {
    setisLeadCamVisible(!isLocalVideoDisabled);
    webRTCHandler.toggleCamera(isLocalVideoDisabled);

    setIsLocalVideoDisabled(!isLocalVideoDisabled);
  };
  const copyHyperlink = async () => {
    setOpenSnackBar(true);
    let Link = configData.WEBRTCPAGE_URL + "?id=" + props.roomId + "&firmname=" + firmNameForLead;
    await navigator.clipboard.writeText(Link);
  }
  return (
    <>
      <div className="video_section_container"></div>
      <div id="ButtonsDivId">
        <Box component="form" className="CenterItem" style={{ backgroundColor: "white", borderRadius: "4px" }}  >
          {/* <CircularProgress /> */}
          <Grid style={{ display: props.isFromWeaseAndManager ? "block" : "none" }} item={true} md={12} xs={12} className="infoText" >
            Manager can only view ..
          </Grid>
          <Grid container display="flex" style={{ width: props.isFromWease ? "300px" : "500px" }} rowSpacing={2} columnSpacing={2} className="custommuigrid"  >

            <Grid style={{ display: !props.isFromWease ? "block" : "none" }} item={true} md={10} xs={10} >
              <div style={{ display: isLeadCamVisible ? "block" : "none", fontSize: "13px;" }}> <label>Your video is hidden</label></div>   <br />
              <label>Name</label>
              <TextField id="nameId"
                inputProps={{
                  style: {
                    height: "11px",
                  },
                }}
                fullWidth
                value={props.name}
                onChange={(e) => {
                  store.dispatch(setLeadFullName(e.target.value))
                }}
              />
            </Grid>
          </Grid>
          <Grid container display="flex" style={{ width: props.isFromWease ? "300px" : "500px" }} rowSpacing={2} columnSpacing={2} className="custommuigrid"  >
            <Grid item={true} md={!props.isFromWease ? 2 : 3} xs={!props.isFromWease ? 2 : 3} className="centerbutton" id="MicrophoneDivId" >
              <Tooltip title="Mute&unmute sound..">
                <Button disabled={props.isFromWeaseAndManager} type="button" style={{ marginTop: '26px' }} size="medium" variant={!isMicMuted ? 'outlined' : 'contained'} onClick={handleMicButtonPressed} startIcon={!isMicMuted ? <MicIcon /> : <MicOffIcon />}  >  </Button>
              </Tooltip>
            </Grid>

            <Grid item={true} md={!props.isFromWease ? 2 : 3} xs={!props.isFromWease ? 2 : 3} className="centerbutton"  >
              <Tooltip title="Open&close camera..">
                {!connectOnlyWithAudio && <Button disabled={props.isFromWeaseAndManager} style={{ marginTop: '26px' }} type="button" size="medium" variant={!isLocalVideoDisabled ? 'outlined' : 'contained'} onClick={handleCameraButtonPressed} startIcon={!isLocalVideoDisabled ? <CameraOpen /> : <CameraClosed />} > </Button>}
              </Tooltip>
            </Grid>
            {props.isFromWease && <Grid item={true} md={!props.isFromWease ? 2 : 3} xs={!props.isFromWease ? 2 : 3} className="centerbutton"  >
              <Tooltip title="Copy meeting link..">
                <Button disabled={props.isFromWeaseAndManager} style={{ marginTop: '26px' }} type="button" size="medium" variant='outlined' onClick={() => copyHyperlink()} startIcon={<ContentCopyIcon />}  > </Button>
              </Tooltip>
            </Grid>}

            {props.isMeetingStarted && <Grid item={true} md={!props.isFromWease ? 2 : 3} xs={!props.isFromWease ? 2 : 3} className="centerbutton"  >
              <Tooltip title="End meeting..">
                <Button disabled={props.isFromWeaseAndManager} style={{ marginTop: '26px', display: props.isFromWease ? 'block' : 'none' }} type="button" size="medium" variant='outlined' onClick={handleRoomDisconnectionForWeaseUser} startIcon={<LogoutIcon />} > </Button>

                <Button style={{ marginTop: '26px', display: props.isFromWease ? 'none' : 'block' }} type="button" size="medium" variant='outlined' onClick={handleRoomDisconnectionForLead} startIcon={<LogoutIcon />} > </Button>

              </Tooltip>
            </Grid>}
            {!props.isFromWease && !props.isMeetingStarted && <Grid item={true} md={2} xs={2} className="centerbutton" >
              <Tooltip title="Connect!">
                <Button onClick={ConnectButton} style={{ marginTop: '26px' }} type="button" size="medium" variant='outlined' startIcon={<LoginIcon />}  > </Button>
              </Tooltip>
            </Grid>}

            {false && <Grid item={true} md={2} xs={2} className="centerbutton" >
              <Tooltip title="Connect audio only!">
                <Button onClick={ConnectAudioOnlyButton} style={{ marginTop: '26px' }} type="button" size="medium" variant='outlined' startIcon={<VideocamOffIcon />}  > </Button>
              </Tooltip>
            </Grid>}

            <Grid style={{ display: "none" }} item={true} md={7} xs={7} >
              <label> Id</label>
              <TextField id="roomId"
                defaultValue={roomId}
                value={roomId}
                inputProps={{
                  style: {
                    height: "11px",
                  },
                }}
                fullWidth
                onChange={(e) => {
                  setRoomId(
                    prev => ({ ...prev, roomId: e.target.value })
                  )
                }}
              />

              <TextField style={{ display: "none" }}
                defaultValue={firmNameForLead}
                value={firmNameForLead}

              />

            </Grid>
            {props.isFromWease && <Grid style={{ display: "none" }} item={true} md={12} xs={12} >
              <label>Copy Link</label>
              <TextField id="hyperlinkId" value={configData.WEBRTCPAGE_URL + "?id=" + props.roomId}
                inputProps={{
                  style: {
                    height: "11px",
                  },
                }}
                fullWidth
                onChange={(e) => {
                  setHyperlinkId(
                    prev => ({ ...prev, hyperlinkId: e.target.value })
                  )
                }}
              />
            </Grid>}

            <Grid style={{ display: "none" }} item={true} md={2} xs={2} className="centerbutton" >
              <Tooltip title="Record meeting..">
                {!connectOnlyWithAudio && <Button type="button" size="medium" variant='outlined' onClick={handleRecordButtonPressed} startIcon={<AdjustIcon />} > </Button>}
              </Tooltip>
            </Grid>

            <Grid style={{ display: "none" }} item={true} md={2} xs={2} className="centerbutton" >
              <Tooltip title="Share screen..">
                {!connectOnlyWithAudio && <Button type="button" size="medium" variant={!isScreenSharingActive ? 'outlined' : 'contained'} onClick={handleScreenShareToggle} startIcon={!isScreenSharingActive ? <ScreenShare /> : <StopScreenShare />}  > </Button>}
              </Tooltip>
            </Grid>

            {/* <Grid item={true} md={12} >
            <div className="room_label">
              <p className="room_label_paragraph">ID: {props.roomId} </p>
            </div>
          </Grid> */}
          </Grid>


          <Grid item={true} md={12} xs={12} >
            {isScreenSharingActive && (
              <LocalScreenSharingPreview stream={screenSharingStream} />
            )}
          </Grid>

        </Box>
      </div >
      <Snackbar open={openSnackBar} autoHideDuration={1000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Link copied to clipboard..
        </Alert>
      </Snackbar>
    </>
  );
});


export default VideoSection;
