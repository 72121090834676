import React, { useState, useEffect } from 'react';
import { MenuItem, TextField, DialogActions, Grid, Button, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import configData from "../../../src/config.json";
import dayjs from 'dayjs';
import Select, { SelectChangeEvent } from '@mui/material/Select';
export const SchedulerSetOperationSoldEditForm = (props) => {
    const [errorResult, setErrorResult] = useState('')
    const [errorResultStyle, setErrorResultStyle] = useState('')
    const [updateMessageData, setUpdateMessageData] = useState({})
    const checkOperationDate = (e) => {
        var operationdate = updateMessageData.operationDateRawId;
        if (operationdate == undefined) {
            return true;
        }

        //else {
        //     debugger;
        //     var OperationDateDay = updateMessageData.startTime.getDate();
        //     var OperationDateMonth = updateMessageData.startTime.getMonth() + 1;
        //     var OperationDateYear = updateMessageData.startTime.getFullYear();

        //     var OperationEndDateDay = e.$d.getDate();
        //     var OperationEndDateMonth = e.$d.getMonth() + 1;
        //     var OperationEndDateYear = e.$d.getFullYear();

        //     if (OperationDateYear > OperationEndDateYear) {
        //         return true;
        //     } else if (OperationDateYear == OperationEndDateYear && OperationDateMonth > OperationEndDateMonth) {
        //         return true;
        //     } else if (OperationDateYear == OperationEndDateYear && OperationDateMonth == OperationEndDateMonth && OperationDateDay > OperationEndDateDay) {
        //         return true;
        //     } else if (OperationDateYear == OperationEndDateYear && OperationDateMonth == OperationEndDateMonth && OperationDateDay == OperationEndDateDay) {
        //         return false;
        //     } else {
        //         return false;
        //     }
        // }
    }

    const dispatch = useDispatch()

    useEffect(() => {

        setUpdateMessageData({
            agentSoldPerformanceId: props.schedulerSetOprSoldEditData.agentSoldPerformanceId,
            leadId: props.schedulerSetOprSoldEditData.id,
            countryId: props.schedulerSetOprSoldEditData.countryId,
            currencyId: props.schedulerSetOprSoldEditData.currencyId,
            deposit: props.schedulerSetOprSoldEditData.deposit,
            startTime: props.schedulerSetOprSoldEditData.startTime,
            endTime: props.schedulerSetOprSoldEditData.endTime,
            leadProductId: props.schedulerSetOprSoldEditData.leadProductId,
            totalPrice: props.schedulerSetOprSoldEditData.totalPrice,
            operationNotes: props.schedulerSetOprSoldEditData.operationNotes,
            operationExplain: props.schedulerSetOprSoldEditData.operationExplain
        })
    }, []);


    const handleSetOperationSoldEditFormSubmit = (event) => {
        event.preventDefault();
        var token = props.ReturnToken;
        axios.post(configData.SCHEDULERSERVERAPI_URL + "/leaddetails/updatesalesoperationsformobile", {
            AgentSoldPerformanceId: updateMessageData.agentSoldPerformanceId,
            LeadId: updateMessageData.leadId,
            LeadProductId: updateMessageData.leadProductId,
            CurrencyId: updateMessageData.currencyId,
            Deposit: updateMessageData.deposit,
            TotalPrice: updateMessageData.totalPrice,
            OperationDate: updateMessageData.startTime,
            OperationEndDate: updateMessageData.endTime,
            OperationNotes: updateMessageData.operationNotes,
            OperationExplain: updateMessageData.operationExplain,
        }, {
            headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
        }).then(function (response) {
            if (response.data.success == true) {
                props.setDialogOperationSoldEditFormOpen(false)
                setErrorResult("Success..");
                setErrorResultStyle("blue");
                props.RefreshScheduler();
            } else {
                setErrorResult(response.data.message);
                setErrorResultStyle("red");
            }
        })
            .catch(function (response) {
                //  props.setDialogOperationSoldFormEditOpen(false)
                if (response.data) {
                    setErrorResult(response.data.message);
                    setErrorResultStyle("red");
                } else {
                    setErrorResult(response.message);
                    setErrorResultStyle("red");
                }
            });

    };



    return (

        <Box component="form" onSubmit={handleSetOperationSoldEditFormSubmit}    >
            <div className='row'>
                <div className='col-4'>
                    <label className="LabelStyle">      Operation Type</label>
                </div>
                <div className='col-8'>
                    <Select
                        size="small" id="leadProductId" fullWidth
                        defaultValue={props.schedulerSetOprSoldEditData.leadProductId}
                        error={updateMessageData.leadProductId == undefined ? true : false}
                        helperText={updateMessageData.leadProductId == undefined ? "Please enter operation type.." : ""}
                        onChange={(e) => {
                            setUpdateMessageData(
                                prev => ({ ...prev, leadProductId: e.target.value })
                            )
                        }}
                        required
                    >
                        {props.leadProducts?.map((data) => (
                            <MenuItem value={data.leadProductId} >{data.name} ({data.countryName}-{data.currencyName})</MenuItem>
                        ))}
                    </Select>
                </div>
            </div>

            <div className='row mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Operation Explain     </label>
                </div>
                <div className='col-8'>
                    <TextField id="operationExplain"
                        value={updateMessageData?.operationExplain}
                        placeholder="" fullWidth variant="outlined" size="small" onChange={(e) => {
                            setUpdateMessageData(
                                prev => ({ ...prev, operationExplain: e.target.value })
                            )
                        }} multiline rows={2} />
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Currency   </label>
                </div>
                <div className='col-8'>
                    <Select
                        size="small" id="currencyId" fullWidth
                        defaultValue={props.schedulerSetOprSoldEditData.currencyId}
                        error={updateMessageData.currencyId == undefined ? true : false}
                        helperText={updateMessageData.currencyId == undefined ? "Please enter currency.." : ""}
                        onChange={(e) => {
                            setUpdateMessageData(
                                prev => ({ ...prev, currencyId: e.target.value })
                            )
                        }}
                        required
                    >
                        {props.currencies?.map((data) => (
                            <MenuItem value={data.id} >{data.name}</MenuItem>
                        ))}
                    </Select>
                </div>
            </div>


            <div className='row mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Deposit   </label>
                </div>
                <div className='col-8'>
                    <TextField id="deposit"

                        onChange={e =>

                            setUpdateMessageData(
                                prev => ({ ...prev, deposit: e.target.value })
                            )

                        }
                        value={updateMessageData?.deposit}
                        error={updateMessageData.deposit == undefined ? true : false}
                        helperText={updateMessageData.deposit == undefined ? "Please enter deposit.." : ""}
                        placeholder="" type="number" fullWidth variant="outlined" size="small" required />
                </div>
            </div>

            <div className='row mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Total Price   </label>
                </div>
                <div className='col-8'>
                    <Grid item={true} md={7}>
                        <TextField id="totalPrice"
                            value={updateMessageData?.totalPrice}
                            error={updateMessageData.totalPrice == undefined ? true : false}
                            helperText={updateMessageData.totalPrice == undefined ? "Please enter total price.." : ""}

                            placeholder="" type="number" fullWidth variant="outlined" size="small" onChange={(e) => {
                                setUpdateMessageData(
                                    prev => ({ ...prev, totalPrice: e.target.value })
                                )
                            }} required />

                    </Grid>
                </div>
            </div>

            <div className='row mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Operation Date    </label>
                </div>
                <div className='col-8'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker id="startTime"
                            disablePast="true"

                            placeholder="" fullWidth ampm={false} slotProps={{ textField: { size: 'small', required: true } }} format="DD.MM.YYYY HH:mm"
                            onChange={(e) => {
                                var Date1 = props.formatDate2(e.$d);
                                setUpdateMessageData(
                                    prev => ({ ...prev, startTime: Date1, operationDateRawId: e.$d })
                                );
                            }} value={dayjs(updateMessageData.startTime)}
                            required />
                    </LocalizationProvider>
                </div>
            </div>


            <div className='row mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Operation End Date     </label>
                </div>
                <div className='col-8'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker id="endTime"
                            disablePast="true"
                            //shouldDisableDate={checkOperationDate}
                            shouldDisableDate={false}
                            placeholder="" fullWidth ampm={false} slotProps={{ textField: { size: 'small' } }} format="DD.MM.YYYY HH:mm"
                            onChange={(e) => {
                                var Date1 = props.formatDate2(e.$d);
                                setUpdateMessageData(
                                    prev => ({ ...prev, endTime: Date1 })
                                );
                            }} value={dayjs(updateMessageData.endTime)}
                            required />
                    </LocalizationProvider>
                </div>
            </div>

            <div className='row mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Operation Notes     </label>
                </div>
                <div className='col-8'>
                    <TextField id="operationNotes"
                        value={updateMessageData?.operationNotes}
                        error={updateMessageData.operationNotes == undefined ? true : false}
                        helperText={updateMessageData.operationNotes == undefined ? "Please enter some notes.." : ""}

                        placeholder="" fullWidth variant="outlined" size="small" onChange={(e) => {
                            setUpdateMessageData(
                                prev => ({ ...prev, operationNotes: e.target.value })
                            )
                        }} multiline rows={3} required />
                </div>
            </div>

            <div className='row mt-2'>
                <div className='col-12'>
                    <label style={{ color: `${errorResultStyle}` }}>{errorResult}</label>
                </div>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <DialogActions>
                        <Button size="small" startIcon={<CancelIcon />} variant='contained' onClick={() => props.setDialogOperationSoldEditFormOpen(false)}
                            color="error">Cancel</Button>
                        <Button type="submit" style={{ width: '100px', height: '30px', display: props.showUpdateButton == true ? 'block flex' : 'none' }} size="small" startIcon={<SaveIcon />} variant='contained' color="success"  >Save</Button>
                    </DialogActions>
                </div>
            </div>
        </Box >
    )
}
