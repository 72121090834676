import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, TextField, Button, MenuItem } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import configData from "../../../src/config.json";
import axios from 'axios';
import './smartchatbotsettings.css';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { v4 as uuidv4 } from "uuid";
import ButtonGroup from '@mui/material/ButtonGroup';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
export default function SmartChatBotSettings() {
    const Alert = React.forwardRef(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    }
    const [belongingQuestions, setBelongingQuestions] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
    const [isSuccess, setIsSuccess] = useState(false);
    const [questions, setQuestions] = useState([{ id: 0, masterId: 0, masterOptionId: 0, orderNo: 1, question: "", questionType: 2, scbOptions: [{ id: 0, optionVal: "", orderNo: 1, questionId: 0 }], scbDdOptions: [], isSaved: false }]);
    const handleAddOptionText = (id, orderNo, optionOrderNo) => {
        let _todo = questions.find(g => g.orderNo == orderNo)
        if (_todo.scbOptions[optionOrderNo].optionVal == "") {
            setIsSuccess(false);
            ArrangeErrorMessage("Please enter some option content..", orderNo);
        } else {
            const newTodos = [...questions];
            let newOption = {};
            newOption.id = 0;
            newOption.optionVal = "";
            newOption.orderNo = optionOrderNo + 2;
            newOption.questionId = id;
            newTodos.filter((obj) => obj.id === id)[0].scbOptions.push(newOption);
            setQuestions(newTodos);
        }
    }

    const ArrangeErrorMessage = (e, questionOrderNo) => {
        setErrorMessage(e);
        setOpenSnackBar(true);
    }
    const handleDeleteOptionText = (id, i, i2, questionId) => {
        if (id == 0) {
            const newTodos = [...questions];
            newTodos[i - 1].scbOptions.splice(i2, 1);
            setQuestions(newTodos);
        } else {
            const token = ReturnIdentity().token;
            axios.delete(configData.SMARTCHATBOTAPI_URL + '/smartchatbot/deletescboption?optionId=' + id, {
                headers: {
                    "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.data.success === true) {
                        setIsSuccess(true);
                        ArrangeErrorMessage("Success..", i);
                        GetQuestionsWithOptions();
                        FillBelongingQuestions();
                        let _todo = questions.find(g => g.id == questionId);
                        let _todoOptions = _todo.scbOptions.filter((g) => g.id != id);
                        if (_todoOptions.length == 0) {
                            //eğer sorunun son option da silinirse tipi text e dönüştürülüyor.....
                            _todo.questionType = 2;
                            SaveQuestionAndOptions(_todo, i);
                        }
                    } else {
                        setIsSuccess(false);
                        ArrangeErrorMessage(response.data.message, i);
                    }
                })
                .catch(error => {
                });
        }
    }
    const handleTodoChange = (e, i, i2) => {
        let NewIndex = i - 1;
        const field = e.target.name;
        if (field == "optionname") {
            const newTodos = [...questions];
            newTodos[NewIndex].scbOptions[i2].optionVal = e.target.value;
            setQuestions(newTodos);
        } else if (field == "belongingQuestion") {
            const newTodos = [...questions];
            newTodos[NewIndex].masterId = e.target.value;
            setQuestions(newTodos);
            FillBelongingOptions(e.target.value, i);
        } else if (field == "belongingOption") {
            const newTodos = [...questions];
            newTodos[NewIndex].masterOptionId = e.target.value;
            setQuestions(newTodos);
        } else if (field == "questionType") {
            const newTodos = [...questions];
            newTodos[NewIndex][field] = e.target.value;
            if (newTodos[NewIndex].questionType == 1 || newTodos[NewIndex].questionType == 3) {  ///eğer text-> option dönüşümü var ise
                let newOption = {};
                newOption.id = 0;
                newOption.optionVal = "";
                newOption.orderNo = 1;
                newOption.questionId = newTodos[NewIndex].id;
                newTodos[NewIndex].scbOptions.push(newOption);
            }
            setQuestions(newTodos);
        }
        else {
            const newTodos = [...questions];
            newTodos[NewIndex][field] = e.target.value;
            setQuestions(newTodos);
        }
    };
    const handleAddQuestion = (e, questionId) => {
        let OptionArr = [];
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.SMARTCHATBOTAPI_URL + '/smartchatbot/GetSCBQptions?questionId=' + questionId,
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                for (var i = 0; i < response.data.data.length; i++) {
                    let Obj = {
                        id: response.data.data[i].id,
                        orderNo: response.data.data[i].orderNo
                    };
                    OptionArr.push(Obj);
                }
                setQuestions([...questions, { id: 0, masterId: questionId, masterOptionId: 0, orderNo: questions.length + 1, question: "", questionType: 2, scbOptions: [{ id: 0, optionVal: "", orderNo: 1, questionId: 0 }], scbDdOptions: OptionArr, isSaved: false, }]);
            });
    };

    const handleDeleteTodo = (id, questionOrderNo) => {
        if (id > 0) {
            const token = ReturnIdentity().token;
            axios.delete(configData.SMARTCHATBOTAPI_URL + '/smartchatbot/deletescbquestion?questionId=' + id, {
                headers: {
                    "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.data.success === true) {
                        setIsSuccess(true);
                        ArrangeErrorMessage("Success..", questionOrderNo);
                        // const newTodos = [...questions];
                        // newTodos.splice(questionOrderNo - 1, 1);
                        // newTodos.map((val, orderNo) => {
                        //     val.orderNo = orderNo + 1;
                        // })
                        // setQuestions(newTodos);
                        GetQuestionsWithOptions();
                        FillBelongingQuestions();
                    } else {
                        setIsSuccess(false);
                        ArrangeErrorMessage(response.data.message, questionOrderNo);
                    }
                })
                .catch(error => {
                    ArrangeErrorMessage("An error occured..", questionOrderNo);
                });
        } else {
            const newTodos = [...questions];
            newTodos.splice(questionOrderNo - 1, 1);
            newTodos.map((val, orderNo) => {
                val.orderNo = orderNo + 1;
            })
            setQuestions(newTodos);
        }
    }

    const handleSubmit = (event, i) => {
        event.preventDefault();
        let NewIndex = i - 1;
        const newTodos = [...questions];
        newTodos[NewIndex].isSaved = true;
        let ErrorMsg = "";
        if (newTodos[NewIndex].question == "") {
            ErrorMsg += "Please enter some question content..";
        }
        if (ErrorMsg == "" && (newTodos[NewIndex].questionType == 1 || newTodos[NewIndex].questionType == 3)) {
            for (let j = 0; j < newTodos[NewIndex].scbOptions.length; j++) {
                if (newTodos[NewIndex].scbOptions[j].optionVal == "") {
                    ErrorMsg += "Please enter some option content..";
                    break;
                }
            }
        }
        if (ErrorMsg == "") {
            setQuestions(newTodos);
            SaveQuestionAndOptions(questions[NewIndex], i);
        } else {
            setIsSuccess(false);
            ArrangeErrorMessage(ErrorMsg, i);
        }
    }

    const [questionTypes, setQuestionTypes] = useState([]);
    const [workingHours, setWorkingHours] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [selectedWorkingHour, setSelectedWorkingHour] = useState('')
    const [selectedLanguage, setSelectedLanguage] = useState('')
    const [isSmartChatBotChecked, setSmartChatBotChecked] = useState('')
    const ReturnIdentity = () => {
        // var Token = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI3MSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL3NpZCI6IjQiLCJ1bmlxdWVfbmFtZSI6IkJlcmthbiBPa3VyIiwicm9sZSI6IlNhbGVzTWFuYWdlciIsIm5iZiI6MTcxMzI1MDA1NiwiZXhwIjoxNzEzNjgyMDU2LCJpYXQiOjE3MTMyNTAwNTZ9.mzOWB2Sb8S_RJzZSlwIJNzXuWr4e0RDNnZb3kz9xtWDGdJxHhEJKa1_eJidhDJpX6WRFcFTv50LVdoQppthzuw";
        // var FirmId = "4";
        // var UserId = "168";
        // var OperationClaimId = "2";
        var Token = "";
        var FirmId = "";
        var UserId = "";
        var OperationClaimId = "";
        var url = window.location.search.substring(1);
        var qArray = url.split('&');
        for (var i = 0; i < qArray.length; i++) {
            var pArr = qArray[i].split('=');
            if (pArr[0] == "token")
                Token = pArr[1];
            if (pArr[0] == "firmId")
                FirmId = pArr[1];
            if (pArr[0] == "userId")
                UserId = pArr[1];
            if (pArr[0] == "operationClaimId")
                OperationClaimId = pArr[1];
        }
        var Identity = {
            token: Token,
            firmId: FirmId,
            userId: UserId,
            operationClaimId: OperationClaimId
        };
        return Identity;
    }
    const SaveQuestionAndOptions = async (data, orderNo) => {
        const token = ReturnIdentity().token;
        let Arr1 = [];
        if (data.questionType == 1 || data.questionType == 3) {
            if (data.id == 0) {
                for (var i = 0; i < data.scbOptions.length; i++) {
                    Arr1.push({
                        OptionVal: data.scbOptions[i].optionVal,
                        OrderNo: i + 1
                    });
                }
            } else {
                for (var i = 0; i < data.scbOptions.length; i++) {
                    Arr1.push({
                        Id: data.scbOptions[i].id,
                        OptionVal: data.scbOptions[i].optionVal,
                        OrderNo: data.scbOptions[i].orderNo
                    });
                }
            }
        }
        let payload = {};
        if (data.id == 0) {
            payload = {
                id: data.id,
                MasterId: data.masterId,
                MasterOptionId: data.masterOptionId,
                QuestionType: data.questionType,
                Question: data.question,
                OrderNo: data.orderNo,
                CreateSCBOptionList: Arr1
            };
        } else {
            payload = {
                id: data.id,
                MasterId: data.masterId,
                MasterOptionId: data.masterOptionId,
                QuestionType: data.questionType,
                Question: data.question,
                OrderNo: data.orderNo,
                UpdateSCBOptionList: Arr1
            };
        }
        axios({
            url: data.id == 0 ? configData.SMARTCHATBOTAPI_URL + "/smartchatbot/addscbquestionsandoptions" : configData.SMARTCHATBOTAPI_URL + "/smartchatbot/updatescbquestionsandoptions",
            method: data.id == 0 ? 'post' : 'put',
            data: payload,
            headers: {
                "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                if (response.data.success === true) {
                    setIsSuccess(true);
                    ArrangeErrorMessage("Success..", response.data.data.orderNo);
                    GetQuestionsWithOptions();
                    FillBelongingQuestions();
                } else {
                    setIsSuccess(false);
                    ArrangeErrorMessage(response.data.message, orderNo);
                    setTimeout(() => {
                        GetQuestionsWithOptions();
                        FillBelongingQuestions();
                    }, 3000);
                }

            })
            .catch(function (error) {
            });
    }

    const FillQuestionTypes = () => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.SMARTCHATBOTAPI_URL + '/smartchatbot/GetSCBQuestionTypes',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setQuestionTypes(response.data.data);
            })
    }
    const FillBelongingQuestions = () => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.SMARTCHATBOTAPI_URL + '/smartchatbot/GetSCBQuestions',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setBelongingQuestions(response.data.data);
            })
    }
    const FillBelongingOptions = (id, questionOrderNo) => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.SMARTCHATBOTAPI_URL + '/smartchatbot/GetSCBQptions?questionId=' + id,
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                let _questions = questions.filter(g => g.id == id);
                const newQuestions = [...questions];
                newQuestions[questionOrderNo - 1].scbDdOptions = response.data.data;
                setQuestions(newQuestions);
            })
    }
    const FillWorkingHours = () => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.SMARTCHATBOTAPI_URL + '/smartchatbot/returnsmartchatbotworkinghourssselectbox',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setWorkingHours(response.data.data);
            })
    }

    const FillLanguages = () => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.SMARTCHATBOTAPI_URL + '/language/getlanguageswithcode',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setLanguages(response.data);
            })
    }
    const handleWorkingHoursChanged = (e) => {
        const token = ReturnIdentity().token;
        axios({
            url: configData.SMARTCHATBOTAPI_URL + "/firms/UpdateSCBWorkingHours?sCBWorkingHourOptionId=" + e.target.value,
            method: 'put',
            headers: {
                "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                if (response.data.success === true) {
                    setIsSuccess(true);
                    ArrangeErrorMessage("Success..");
                    setSelectedWorkingHour(response.data.data.scbWorkinHours);
                } else {
                    setIsSuccess(false);
                    ArrangeErrorMessage(response.data.message);
                }
            })
            .catch(function (error) {

            });
    }

    const handleLanguageChanged = (e) => {
        const token = ReturnIdentity().token;
        axios.post(configData.SMARTCHATBOTAPI_URL + "/firms/UpdateSCBLanguageCode", {
            languageCode: e.target.value
        }, {
            headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
        }).then(function (response) {
            if (response.data.success == true) {
                setIsSuccess(true);
                ArrangeErrorMessage("Success..");
                setSelectedLanguage(response.data.data.scbLanguageCode);
            } else {
                setIsSuccess(false);
                ArrangeErrorMessage(response.data.message);
            }
        })
            .catch(function (response) {
                setIsSuccess(false);
            });
    }
    const handleSmartChatBotChecked = (e) => {
        const token = ReturnIdentity().token;
        axios({
            url: configData.SMARTCHATBOTAPI_URL + "/firms/updatescbonoff",
            method: 'put',
            headers: {
                "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                if (response.data.success === true) {
                    setIsSuccess(true);
                    ArrangeErrorMessage("Success..");
                    setSmartChatBotChecked(response.data.data.scbOnOff);
                } else {
                    setIsSuccess(false);
                    ArrangeErrorMessage(response.data.message);
                }

            })
            .catch(function (error) {

            });
    }

    const GetQuestionsWithOptions = () => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.SMARTCHATBOTAPI_URL + '/smartchatbot/getscbquestionswithoptions',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                let newTodos = [];
                let OptionArr = [];
                setSmartChatBotChecked(response.data.data.scbOnOff);
                setSelectedWorkingHour(response.data.data.workingHours);
                setSelectedLanguage(response.data.data.scbLanguageCode);
                if (response.data.data.scbQuestionDtos.length == 0) {
                    setQuestions([{ id: 0, masterId: 0, masterOptionId: 0, orderNo: 1, question: "", questionType: 2, scbOptions: [{ id: 0, optionVal: "", orderNo: 1, questionId: 0 }], scbDdOptions: [], isSaved: false, }]);
                } else {
                    for (var i = 0; i < response.data.data.scbQuestionDtos.length; i++) {
                        let OptionArr = [];
                        let OptionDdArr = [];

                        for (let j = 0; j < response.data.data.scbQuestionDtos[i].scbOptionDtos.length; j++) {
                            OptionArr.push({ id: response.data.data.scbQuestionDtos[i].scbOptionDtos[j].id, optionVal: response.data.data.scbQuestionDtos[i].scbOptionDtos[j].optionVal, orderNo: response.data.data.scbQuestionDtos[i].scbOptionDtos[j].orderNo, questionId: response.data.data.scbQuestionDtos[i].scbOptionDtos[j].questionId });

                        }

                        for (let j = 0; j < response.data.data.scbQuestionDtos[i].scbddOptionDto.length; j++) {
                            OptionDdArr.push({ id: response.data.data.scbQuestionDtos[i].scbddOptionDto[j].id, orderNo: response.data.data.scbQuestionDtos[i].scbddOptionDto[j].orderNo });
                        }

                        ///aşağıdaki kod ile eğer option tipinde bir sorunun son optionu silinirse boş bir option ekleme penceresi gözükür...
                        if ((response.data.data.scbQuestionDtos[i].questionType == 1 || response.data.data.scbQuestionDtos[i].questionType == 3) && response.data.data.scbQuestionDtos[i].scbOptionDtos.length == 0) {
                            OptionArr.push({ id: 0, optionVal: "", orderNo: 0, questionId: response.data.data.scbQuestionDtos[i].id });

                        }

                        newTodos.push({
                            id: response.data.data.scbQuestionDtos[i].id,
                            question: response.data.data.scbQuestionDtos[i].question,
                            questionType: response.data.data.scbQuestionDtos[i].questionType,
                            orderNo: response.data.data.scbQuestionDtos[i].orderNo,
                            isSaved: true,
                            scbOptions: OptionArr,
                            scbDdOptions: OptionDdArr,
                            masterId: response.data.data.scbQuestionDtos[i].masterId,
                            masterOptionId: response.data.data.scbQuestionDtos[i].masterOptionId
                        })

                    }
                    setQuestions(newTodos);
                }
            })
    }
    useEffect(() => {
        FillQuestionTypes();
        FillWorkingHours();
        FillBelongingQuestions();
        FillLanguages();
        GetQuestionsWithOptions();
    }, []);

    return (
        <>
            <div className='SmartChatBotFormMain'>
                <Box component="form" style={{ background: "edf0f5" }}   >
                    <div className='row CenterText'>
                        <div className='col-6'>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={isSmartChatBotChecked} onChange={handleSmartChatBotChecked} />} label="ChatBot On/Off" />
                            </FormGroup>
                        </div>
                    </div>
                    {isSmartChatBotChecked && <div className='row MarginBottom1' >
                        <div className='col-6'>
                            <label>Working Hours</label>
                        </div>
                        <div className='col-6'>
                            <label>Language</label>
                        </div>
                        <div className='col-6 mt-1'>
                            <TextField
                                size="small"
                                name="workingHours"
                                select
                                value={selectedWorkingHour}
                                fullWidth
                                onChange={handleWorkingHoursChanged}
                                required >
                                {workingHours?.map((data) => (
                                    <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
                                ))}
                            </TextField>
                        </div>

                        <div className='col-6 mt-1'>
                            <TextField
                                size="small"
                                name="languages"
                                select
                                value={selectedLanguage}
                                fullWidth
                                onChange={handleLanguageChanged}
                                required >
                                {languages?.map((data) => (
                                    <MenuItem key={data.text2} value={data.text2}>{data.text}</MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>}
                    {isSmartChatBotChecked &&
                        questions.map((todo) => (
                            < div id="AutoChatBotGeneralDivId" key={todo.id}>
                                <input type="hidden" id={todo.id} />
                                <div className='row'>
                                    <div className='col-12 PullLeft'>
                                        Question {todo.orderNo}
                                        <hr />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4 PullLeft'>
                                        <label>Question Type</label>
                                    </div>
                                    {todo.masterId != 0 &&
                                        <>
                                            <div className='col-4'>
                                                <label>Belonging Question</label>
                                            </div>
                                            <div className='col-4'>
                                                <label>Belonging Option</label>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='row mt-1'>
                                    <div className='col-4 PullLeft'>
                                        <TextField
                                            size="small"
                                            name="questionType"
                                            select
                                            fullWidth
                                            value={todo.questionType}
                                            onChange={(e) => handleTodoChange(e, todo.orderNo)}
                                            required >
                                            {questionTypes?.map((data) => (
                                                <MenuItem key={data.value} value={data.value}>{data.name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    {todo.masterId != 0 &&
                                        <>
                                            <div className='col-4'>
                                                <TextField
                                                    size="small"
                                                    name="belongingQuestion"
                                                    select
                                                    onChange={(e) => handleTodoChange(e, todo.orderNo)}
                                                    value={todo.masterId}
                                                    fullWidth
                                                >
                                                    {belongingQuestions?.map((data, i) => {

                                                        if (todo.id == data.id) {
                                                            return <MenuItem key={data.id} value={data.id} disabled> Question {data.orderNo}</MenuItem>
                                                        }
                                                        return <MenuItem key={data.id} value={data.id}> Question {data.orderNo}</MenuItem>
                                                    })}
                                                </TextField>

                                            </div>

                                            <div className='col-4'>
                                                <TextField
                                                    size="small"
                                                    name="belongingOption"
                                                    onChange={(e) => handleTodoChange(e, todo.orderNo)}
                                                    select
                                                    value={todo.masterOptionId}
                                                    fullWidth
                                                >

                                                    {todo.scbDdOptions?.map((data, i) => {
                                                        if (todo.masterOptionId == data.id) {
                                                            return <MenuItem key={data.id} value={data.id} > Option {data.orderNo}</MenuItem>
                                                        }
                                                        return <MenuItem key={data.id} value={data.id}> Option {data.orderNo}</MenuItem>
                                                    })}
                                                </TextField>

                                            </div>
                                        </>
                                    }
                                </div>
                                {(todo.questionType == 1 || todo.questionType == 3) && <><div className='row'>
                                    <div className='col-md-6 mt-2'>
                                        Question
                                    </div>
                                </div>
                                    <div className='row'>
                                        <div className='col-12 mt-2'>
                                            <TextareaAutosize
                                                maxLength={1000}
                                                className='TextAreaClass'
                                                name="question"
                                                value={todo.question}
                                                onChange={(e) => handleTodoChange(e, todo.orderNo)}
                                            />
                                        </div>
                                    </div>

                                    {todo.scbOptions.map((todo2, optionOrderNo) => (
                                        <div className='row mt-1' key={optionOrderNo}>
                                            <div className='col-12 mt-2'> Option {optionOrderNo + 1}</div>
                                            <div className='col-9 mt-1'>
                                                <TextField
                                                    id={todo.scbOptions[optionOrderNo].id}
                                                    inputProps={{ maxLength: 20 }}
                                                    fullWidth
                                                    name="optionname"
                                                    value={todo.scbOptions[optionOrderNo].optionVal}
                                                    variant="outlined"
                                                    onChange={(e) => handleTodoChange(e, todo.orderNo, optionOrderNo)}
                                                />
                                            </div>
                                            <div className='col-3'>
                                                <ButtonGroup style={{ marginTop: "15px", marginLeft: "-10px" }} variant="contained" aria-label="outlined primary button group">
                                                    {optionOrderNo === todo.scbOptions.length - 1 &&
                                                        <Button size="small" onClick={() => handleAddOptionText(todo.id, todo.orderNo, optionOrderNo)} startIcon={<AddIcon className='ButtonIcon' />} variant='contained' color="primary"> </Button>
                                                    }
                                                    <Button size="small" onClick={() => handleDeleteOptionText(todo.scbOptions[optionOrderNo].id, todo.orderNo, optionOrderNo, todo.id)} startIcon={<RemoveIcon className='ButtonIcon' />} variant='contained'
                                                        color="error"> </Button>  <div></div>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    ))}</>
                                }
                                {todo.questionType == 2 && <>
                                    <div className='row'>
                                        <div className='col-12 mt-2'>
                                            Question
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <TextareaAutosize
                                                maxLength={1000}
                                                className='TextAreaClass'
                                                name="question"
                                                value={todo.question}
                                                onChange={(e) => handleTodoChange(e, todo.orderNo)}
                                            />
                                        </div>
                                    </div>
                                </>}
                                <div className='row'>
                                    <div className='col-12 mt-2 '>
                                        <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackBar}>
                                            <Alert    {...isSuccess == true ? { severity: "success" } : { severity: "error" }} sx={{ width: '100%', marginBottom: '50px' }} onClose={handleCloseSnackBar} >
                                                {errorMessage}
                                            </Alert>
                                        </Snackbar>
                                    </div>
                                    <div className='col-12 mt-2 PullRight'>
                                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                            <Button size="small" onClick={(e) => handleSubmit(e, todo.orderNo)} startIcon={<SaveIcon className='ButtonIcon' />} variant='contained'
                                                color="success"> </Button>
                                            {todo.orderNo === questions.length && todo.id > 0 &&
                                                <>
                                                    <Button onClick={(e) => handleAddQuestion(e, todo.id)} size="small" startIcon={<AddIcon className='ButtonIcon' />} variant='contained'
                                                    > </Button>
                                                </>
                                            }
                                            {todo.orderNo !== 1 &&
                                                <Button size="small" onClick={() => handleDeleteTodo(todo.id, todo.orderNo)} startIcon={<RemoveIcon className='ButtonIcon' />} variant='contained'
                                                    color="error"> </Button>

                                            }
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Box >
            </div >
        </>
    )
}
