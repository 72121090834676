import { Scheduler } from "./components/scheduler/Scheduler";
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import './components/reports/report.css';
import ReportsPage from "./components/reports/ReportsPage";
import RoomPage from "./components/webrtcpage/RoomPage";
import WebFormPage from "./components/webform/WebFormPage";
import SmartChatBotSettings from "./components/smartchatbotsettings/SmartChatBotSettings";
import { OfferToPdf } from "./components/offertopdf/OfferToPdf";
import { AddNewTemplateOfferToPdf } from "./components/offertopdf/AddNewTemplateOfferToPdf";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Routes>
          <Route path='/scheduler' element={<Scheduler />} />
          <Route path='/webrtcpage' element={< RoomPage />} />
          <Route path='/reportspage' element={<ReportsPage />} />
          <Route path='/webform' element={<WebFormPage />} />
          <Route path='/smartchatbot' element={<SmartChatBotSettings />} />
          <Route path='/offertopdf' element={<OfferToPdf />} />
          <Route path='/addnewtemplateoffertopdf' element={<AddNewTemplateOfferToPdf />} />
        </Routes>
      </header>
    </div >
  );
}

export default App;
