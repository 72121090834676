import axios from "axios";
import configData from "../../src/config.json";

const serverApi = configData.WEBRTCNODEJSSERVER_URL + "/api";

export const getRoomExists = async (roomId) => {
  const response = await axios.get(`${serverApi}/room-exists/${roomId}`);
  return response.data;
};

export const getTURNCredentials = async () => {
  const response = await axios.get(`${serverApi}/get-turn-credentials`);
  return response.data;
};
