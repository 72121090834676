import React, { useEffect, useRef, useState } from "react";
import VideoSection from "./videosection/VideoSection";
import { connect } from "react-redux";
import * as webRTCHandler from "../../utils/webRTCHandler";
import Overlay from "./Overlay";
import { connectWithSocketIOServer } from '../../utils/wss';
import * as Constants from "../../Constants";
import "./RoomPage.css";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import configData from "../../../src/config.json";
import { setFirmName } from "../../store/actions";
import store from "../../store/store";
import OverlayVideoRecording from "./OverlayVideoRecording";
const RoomPage = ({
    roomId,
    identity,
    isRoomHost,
    showOverlay,
    showOverlayVideoRecording,
    connectOnlyWithAudio,
    isMeetingStarted
}) => {
    configureAbly({ key: configData.ABLY_KEY });
    const [channel] = useChannel("WebRtcEndMeetingForLeads", (message) => {
        var ablyReturnObject = JSON.parse(message.data);
        if (Constants.token == undefined) {///Constants.token==undefined yani bu bir lead...
            window.location.href = "https://www.wease.io";
        }
    });
    const [isFromWease, setIsFromWease] = useState(false);
    const [isFromWeaseAndManager, setIsFromWeaseAndManager] = useState(false)
    const [token, setToken] = useState('');
    useEffect(() => {
        connectWithSocketIOServer(Constants.webRtcRoomId);
    }, []);

    const childRef = useRef(null);

    useEffect(() => {
        isRoomHost = true;
        identity = "";  //childRef.current.returnName();
        connectOnlyWithAudio = false;

        var queryparam = window.location.search.substring(1); //get rid of "?" in querystring
        if (queryparam != "") {
            var queryparamarray = queryparam.split('&');

            for (var i = 0; i < queryparamarray.length; i++) {
                var pArr = queryparamarray[i].split('='); //split key and value
                if (pArr[0] == "id") {
                    childRef.current.setRoomIdFromHyperlink(pArr[1]);
                    Constants.idFromHyperlink = pArr[1];
                }
                if (pArr[0] == "firmname") {
                    childRef.current.setFirmNameFromHyperlink(pArr[1]);
                    store.dispatch(setFirmName(pArr[1].replace('%20', ' ')))
                }
                if (pArr[0] == "isfromwease") {
                    setIsFromWease(true);
                }
                if (pArr[0] == "token") {
                    setToken(pArr[1]);
                    Constants.token = pArr[1];
                }
                if (pArr[0] == "leadid") {
                    Constants.leadId = pArr[1];
                }
                if (pArr[0] == "operationclaimid") {
                    if (pArr[1] == "2") {
                        setIsFromWeaseAndManager(true);
                    } else {
                        setIsFromWeaseAndManager(false);
                    }
                }
                if (pArr[0] == "firmid") {
                    Constants.firmId = pArr[1];
                }
                if (pArr[0] == "userid") {
                    Constants.userId = pArr[1];
                }

            }
        }


        if (!isRoomHost && !roomId) {
            const siteUrl = window.location.origin;
            window.location.href = siteUrl;
        } else {
            webRTCHandler.getLocalPreviewAndInitRoomConnection(
                isRoomHost,
                identity,
                roomId,
                connectOnlyWithAudio,
                isMeetingStarted
            );
        }
    }, []);





    return (
        <div className="room_container">
            <VideoSection isMeetingStarted={isMeetingStarted} isFromWeaseAndManager={isFromWeaseAndManager} isFromWease={isFromWease} roomId={roomId} ref={childRef} />
            {showOverlay && <Overlay />}
            {showOverlayVideoRecording && <OverlayVideoRecording />}
        </div>
    );
};

const mapStoreStateToProps = (state) => {

    return {
        ...state,
    };
};

export default connect(mapStoreStateToProps)(RoomPage);
