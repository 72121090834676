import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, TextField, Button, MenuItem } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SearchIcon from '@mui/icons-material/Search';
import configData from "../../../src/config.json";
import axios from 'axios';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { BoxWidget } from './BoxWidget';
import { HorizontalBarChart } from './HorizontalBarChart';
import { WorldMapChart } from './WorldMapChart';
import { TableComponent } from './TableComponent';
import { ChartWidget } from './ChartWidget';
import ButtonGroup from '@mui/material/ButtonGroup';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { AmChartV4WorldMap } from './AmChartV4WorldMap';


export const ReportsPage = () => {
    configureAbly({ key: configData.ABLY_KEY });
    //filters..
    const FormatDecimal = (integerim) => {
        integerim = integerim.toString();
        let spt = integerim.replace(",", ".").split(".");
        let para = spt[0];
        let lengthOfKurusPart = 0;
        let Kuruspart = 0;
        if (spt[1] != undefined) {
            lengthOfKurusPart = spt[1].toString().length;//10
            let Kuruspart = Math.round(spt[1].slice(0, 1) + "." + spt[1].slice(1, lengthOfKurusPart - 1))// 56.57 => 0.57
        }
        let sonuc = "";
        if (para.length <= 3) {
            sonuc = para + "," + Kuruspart;
        }
        else {
            while
                (parseInt(para / 1000) > 0) {
                let tempSonuc = para % 1000;
                while (tempSonuc.toString().length != 3) {
                    tempSonuc = "0" + tempSonuc;
                }
                para = parseInt(para / 1000);
                if (sonuc.toString().length == 0) {
                    sonuc = tempSonuc;
                }
                else {
                    sonuc = tempSonuc + "." + sonuc;
                }
            }

            sonuc = sonuc + "," + Kuruspart;

            if (para.toString().length > 0) {

                sonuc = para + "." + sonuc;
            }
        }
        return sonuc;
    }
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [selectedAgentIds, setSelectedAgentIds] = useState([]);
    const [selectedLeadProductIds, setSelectedLeadProductIds] = useState([]);
    const [selectedChannelIds, setSelectedChannelIds] = useState([]);
    //ably..
    const [channelNewConversation] = useChannel("NewConversationV1", (message) => {
        var ablyReturnObject = JSON.parse(message.data);
        var Identity = ReturnIdentity();
        if (Identity.firmId == ablyReturnObject.FirmId) {
            GetWholeReportSubmit();
        }
    });

    const [channelLeadLost] = useChannel("LeadLost", (message) => {
        var ablyReturnObject = JSON.parse(message.data);
        var Identity = ReturnIdentity();
        if (Identity.firmId == ablyReturnObject.FirmId) {
            GetWholeReportSubmit();
        }
    });
    const [channelLeadUnreachable] = useChannel("LeadUnreachable", (message) => {
        var ablyReturnObject = JSON.parse(message.data);
        var Identity = ReturnIdentity();
        if (Identity.firmId == ablyReturnObject.FirmId) {
            GetWholeReportSubmit();
        }
    });

    const [channelSold] = useChannel("LeadSold", (message) => {
        var ablyReturnObject = JSON.parse(message.data);
        var Identity = ReturnIdentity();
        if (Identity.firmId == ablyReturnObject.FirmId) {
            GetWholeReportSubmit();
        }
    });

    const [channelLeadAssignment] = useChannel("LeadAssignmentV1", (message) => {
        var ablyReturnObject = JSON.parse(message.data);
        var Identity = ReturnIdentity();
        if (Identity.firmId == ablyReturnObject.FirmId) {
            GetWholeReportSubmit();
        }
    });

    const [refreshWeaseReport] = useChannel("RefreshWeaseReport", (message) => {
        var ablyReturnObject = JSON.parse(message.data);
        var Identity = ReturnIdentity();
        if (Identity.firmId == ablyReturnObject.FirmId && Identity.userId == ablyReturnObject.UserId.toString()) {
            ResetReport();
        }
    });

    const ResetReport = () => {
        setSelectedStartDate(getFirstDayOfMonth());
        setSelectedEndDate(getLastDayOfMonth());
        setSelectedCurrency("1");
        if (ReturnIdentity().operationClaimId === "3") {
            setSelectedAgentIds([ReturnIdentity().userId]);
        } else {
            setSelectedAgentIds([]);
        }

        setSelectedChannelIds([]);
        setSelectedLeadProductIds([]);
        GetWholeReportSubmit("reset");
    }
    const [agentIdsDisabled, setAgentIdsDisabled] = useState(false);
    const colorArray = ["#03214c", "#2ea8b7", "#ebc768", "#2ea8b7", "gray"];
    const [showWorldMap, setShowWorldMap] = useState(true)
    const WorldMapSwitchButtonOnClick = () => {
        showWorldMap == true ? setShowWorldMap(false) : setShowWorldMap(true);
    }
    const marker = { dataLabel: { visible: true, position: 'Middle' } };


    //charts
    const [lostDetailsByLeadCount, setLostDetailsByLeadCount] = useState([]);
    const [outOfTopicDetailsByLeadCount, setOutOfTopicDetailsByLeadCount] = useState([]);
    const [revenueByProduct, setRevenueByProduct] = useState([]);
    const [statusChartData, setStatusChartData] = useState([])
    const [salesAmountChartData, setSalesAmountChartData] = useState([]);
    const [salesRevenueChartData, setSalesRevenueChartData] = useState([]);
    const [leadCountChartData, setLeadCountChartData] = useState([]);
    const [conversionByChannelChartData, setConversionByChannelChartData] = useState([]);
    const [agentResponseTimeChartData, setAgentResponseTimeChartData] = useState([]);
    const [conversionByAgentChartData, setConversionByAgentChartData] = useState([]);
    const [revenueTargetRealizationChartData, setRevenueTargetRealizationChartData] = useState([]);
    const [productBasedChartData, setProductBasedChartData] = useState([]);
    const [worldMapChartData, setWorldMapChartData] = useState([]);
    ///widgets
    const [leadCount, setLeadCount] = useState(0)
    const [operationCount, setOperationCount] = useState(0);
    const [targetCount, setTargetCount] = useState(0);
    const [targetRealization, setTargetRealization] = useState(0);
    const [averagePrice, setAveragePrice] = useState(0);
    const [currencySymbol, setCurrencySymbol] = useState('')
    const [conversion, setConversion] = useState(0)
    const [salesCount, setSalesCount] = useState(0)
    const [revenue, setRevenue] = useState(0)
    const [targetRevenue, setTargetRevenue] = useState(0)
    const [revenueTargetRealization, setRevenueTargetRealization] = useState(0)
    const [averageResponseTime, setAverageResponseTime] = useState(0);
    const [salesAmount, setSalesAmount] = useState(0)
    //filters
    const [filterReport, setFilterReport] = useState({})
    const [selectedAgentCount, setSelectedAgentCount] = useState(0)
    const [selectedChannelCount, setSelectedChannelCount] = useState(0)
    const [selectedLeadProductCount, setSelectedLeadProductCount] = useState(0)
    const [agents, setAgents] = useState([]);
    const [channels, setChannels] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [leadproducts, setLeadProducts] = useState([])
    const formatDate = (d) => {
        var dd = d.getDate();
        var mm = d.getMonth() + 1;
        var yyyy = d.getFullYear();
        if (dd < 10) { dd = '0' + dd }
        if (mm < 10) { mm = '0' + mm };
        return d = yyyy + '-' + mm + '-' + dd;
    }
    const getFirstDayOfMonth = () => {
        let date_today = new Date();
        let firstDay = new Date(date_today.getFullYear(), date_today.getMonth(), 1);
        return dayjs(firstDay);
    }

    const getLastDayOfMonth = (d) => {
        let date_today = new Date();
        let lastDay = new Date(date_today.getFullYear(), date_today.getMonth() + 1, 0);
        return dayjs(lastDay);
    }

    const ReturnIdentity = () => {
        var Token = "";
        var FirmId = "";
        var UserId = "";
        var OperationClaimId = "";
        var url = window.location.search.substring(1);
        var qArray = url.split('&');
        for (var i = 0; i < qArray.length; i++) {
            var pArr = qArray[i].split('=');
            if (pArr[0] == "token")
                Token = pArr[1];
            if (pArr[0] == "firmId")
                FirmId = pArr[1];
            if (pArr[0] == "userId")
                UserId = pArr[1];
            if (pArr[0] == "operationClaimId")
                OperationClaimId = pArr[1];
        }
        var Identity = {
            token: Token,
            firmId: FirmId,
            userId: UserId,
            operationClaimId: OperationClaimId
        };
        return Identity;
    }
    useEffect(() => {
        FillAgentsSelectBox();
        FillChannelsSelectBox();
        FillCurrenciesSelectBox();
        FillLeadProductsSelectBox();
        if (ReturnIdentity().operationClaimId === "3") {
            setFilterReport(
                prev => ({ ...prev, currencyId: 1 })
            )
            setSelectedAgentCount(1);
            setAgentIdsDisabled(true);

        } else {
            setFilterReport(
                prev => ({ ...prev, currencyId: 1 })
            )
        }

        ResetReport();

    }, []);

    const GetWholeReportSubmit = (e) => {
        if (e != "reset" && e) {
            e.preventDefault();
        }
        let Identity = ReturnIdentity();
        const token = Identity.token;
        axios.post(configData.REPORTSERVERAPI_URL + "/reports/getwholereport", {
            FirmId: Identity.firmId,
            StartDate: e == "reset" ? getFirstDayOfMonth() : (selectedStartDate ? selectedStartDate : filterReport.startDateId),
            EndDate: e == "reset" ? getLastDayOfMonth() : (selectedEndDate ? selectedEndDate : filterReport.endDateId),
            CurrencyId: e == "reset" ? "1" : (selectedCurrency ? selectedCurrency : filterReport.currencyId),
            AgentIdList: e == "reset" ? (ReturnIdentity().operationClaimId === "3" ? [ReturnIdentity().userId] : []) : (ReturnIdentity().operationClaimId === "3" ? [ReturnIdentity().userId] : (selectedAgentIds ? selectedAgentIds : filterReport.agentIds)),
            ChannelNameList: e == "reset" ? [] : (selectedChannelIds ? selectedChannelIds : filterReport.channelIds),
            ProductIdList: e == "reset" ? [] : (selectedLeadProductIds ? selectedLeadProductIds : filterReport.leadProductIds)
        }, {
            headers: {
                "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            if (response.data.success == true) {
                let worldMapChartArray = [];
                for (var i = 0; i < response.data.data.countryMapDtoList.length; i++) {
                    worldMapChartArray.push({
                        country: response.data.data.countryMapDtoList[i].countryName, leadCount: response.data.data.countryMapDtoList[i].leadCount, conversion: response.data.data.countryMapDtoList[i].conversion, operationCount: response.data.data.countryMapDtoList[i].operationCount, salesCount: response.data.data.countryMapDtoList[i].salesCount, countryIso2: response.data.data.countryMapDtoList[i].countryIso2
                    });
                }
                setWorldMapChartData(worldMapChartArray);
                setLeadCount(response.data.data.leadCount);
                setOperationCount(response.data.data.operatiionCount);
                setTargetCount(response.data.data.targetCount);
                setTargetRealization(parseFloat(response.data.data.targetRealization.replace(',', '.')).toFixed(0));
                setAveragePrice(FormatDecimal(response.data.data.averagePrice));
                setCurrencySymbol(response.data.data.currencySymbol);
                setConversion(parseFloat(response.data.data.conversion.replace(',', '.')).toFixed(0));
                setSalesCount(response.data.data.salesCount);
                setRevenue(FormatDecimal(response.data.data.revenue));
                setTargetRevenue(FormatDecimal(response.data.data.targetRevenue));
                setRevenueTargetRealization(FormatDecimal(response.data.data.targetRevenueRealization));
                setAverageResponseTime(FormatDecimal(response.data.data.averageResponseTime));
                setSalesAmount(FormatDecimal(response.data.data.salesAmount));

                let StatusChartArray = [];
                if (response.data.data.unassignedCount > 0)
                    StatusChartArray.push({ x: 'Unassigned', y: response.data.data.unassignedCount, color: colorArray[0] });
                if (response.data.data.newLeadCount > 0)
                    StatusChartArray.push({ x: 'New Lead', y: response.data.data.newLeadCount, color: colorArray[1] });
                if (response.data.data.firstEngagementCount > 0)
                    StatusChartArray.push({ x: 'First Engagement', y: response.data.data.firstEngagementCount, color: colorArray[2] });
                if (response.data.data.discoveryCount > 0)
                    StatusChartArray.push({ x: 'Discovery', y: response.data.data.discoveryCount, color: colorArray[3] });
                if (response.data.data.negotiationCount > 0)
                    StatusChartArray.push({ x: 'Negotiation', y: response.data.data.negotiationCount, color: colorArray[0] });
                if (response.data.data.soldCount > 0)
                    StatusChartArray.push({ x: 'Sold', y: response.data.data.soldCount, color: colorArray[1] });
                if (response.data.data.unreachableCount > 0)
                    StatusChartArray.push({ x: 'Unreachable', y: response.data.data.unreachableCount, color: colorArray[2] });
                if (response.data.data.lostCount > 0)
                    StatusChartArray.push({ x: 'Lost', y: response.data.data.lostCount, color: colorArray[3] });
                if (response.data.data.lostCount > 0)
                    StatusChartArray.push({ x: 'Out Of Topic', y: response.data.data.outOfTopicCount, color: colorArray[0] });
                setStatusChartData(StatusChartArray);

                let salesAmountChartArray = [];
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "January") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "January").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'January', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "January").revenueInSelectedCurrency), color: colorArray[0] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "February") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "February").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'February', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "February").revenueInSelectedCurrency), color: colorArray[1] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "March") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "March").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'March', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "March").revenueInSelectedCurrency), color: colorArray[2] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "April") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "April").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'April', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "April").revenueInSelectedCurrency), color: colorArray[3] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "May") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "May").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'May', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "May").revenueInSelectedCurrency), color: colorArray[0] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "June") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "June").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'June', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "June").revenueInSelectedCurrency), color: colorArray[1] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "July") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "July").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'July', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "July").revenueInSelectedCurrency), color: colorArray[2] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "August") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "August").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'August', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "August").revenueInSelectedCurrency), color: colorArray[3] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "September") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "September").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'September', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "September").revenueInSelectedCurrency), color: colorArray[0] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "October") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "October").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'October', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "October").revenueInSelectedCurrency), color: colorArray[1] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "November") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "November").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'November', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "November").revenueInSelectedCurrency), color: colorArray[2] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "December") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "December").revenueInSelectedCurrency != 0)
                    salesAmountChartArray.push({ x: 'December', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "December").revenueInSelectedCurrency), color: colorArray[3] });
                setSalesAmountChartData(salesAmountChartArray);


                let salesRevenueChartArray = [];
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "January") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "January").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'January', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "January").revenueInSelectedCurrency), color: colorArray[0]
                    });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "February") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "February").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'February', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "February").revenueInSelectedCurrency), color: colorArray[1]
                    });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "March") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "March").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'March', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "March").revenueInSelectedCurrency), color: colorArray[2]
                    });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "April") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "April").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'April', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "April").revenueInSelectedCurrency), color: colorArray[3]
                    });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "May") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "May").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'May', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "May").revenueInSelectedCurrency), color: colorArray[0]
                    });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "June") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "June").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'June', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "June").revenueInSelectedCurrency), color: colorArray[1]
                    });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "July") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "July").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'July', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "July").revenueInSelectedCurrency), color: colorArray[2]
                    });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "August") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "August").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'August', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "August").revenueInSelectedCurrency), color: colorArray[3]
                    });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "September") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "September").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'September', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "September").revenueInSelectedCurrency), color: colorArray[0]
                    });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "October") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "October").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'October', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "October").revenueInSelectedCurrency), color: colorArray[1]
                    });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "November") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "November").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'November', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "November").revenueInSelectedCurrency), color: colorArray[2]
                    });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "December") != undefined && response.data.data.monthBasedDtoList.find((e) => e.monthName == "December").revenueInSelectedCurrency != 0)
                    salesRevenueChartArray.push({
                        x: 'December', y: parseFloat(response.data.data.monthBasedDtoList.find((e) => e.monthName == "December").revenueInSelectedCurrency), color: colorArray[3]
                    });
                setSalesRevenueChartData(salesRevenueChartArray);

                let leadCountChartArray = [];
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "January") != undefined)
                    leadCountChartArray.push({ x: 'January', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "January").leadCount, color: colorArray[0] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "February") != undefined)
                    leadCountChartArray.push({ x: 'February', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "February").leadCount, color: colorArray[1] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "March") != undefined)
                    leadCountChartArray.push({ x: 'March', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "March").leadCount, color: colorArray[2] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "April") != undefined)
                    leadCountChartArray.push({ x: 'April', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "April").leadCount, color: colorArray[3] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "May") != undefined)
                    leadCountChartArray.push({ x: 'May', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "May").leadCount, color: colorArray[0] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "June") != undefined)
                    leadCountChartArray.push({ x: 'June', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "June").leadCount, color: colorArray[1] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "July") != undefined)
                    leadCountChartArray.push({ x: 'July', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "July").leadCount, color: colorArray[2] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "August") != undefined)
                    leadCountChartArray.push({ x: 'August', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "August").leadCount, color: colorArray[3] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "September") != undefined)
                    leadCountChartArray.push({ x: 'September', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "September").leadCount, color: colorArray[0] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "October") != undefined)
                    leadCountChartArray.push({ x: 'October', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "October").leadCount, color: colorArray[1] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "November") != undefined)
                    leadCountChartArray.push({ x: 'November', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "November").leadCount, color: colorArray[2] });
                if (response.data.data.monthBasedDtoList.find((e) => e.monthName == "December") != undefined)
                    leadCountChartArray.push({ x: 'December', y: response.data.data.monthBasedDtoList.find((e) => e.monthName == "December").leadCount, color: colorArray[3] });
                setLeadCountChartData(leadCountChartArray);


                let conversionByChannelChartArray = [];
                if (response.data.data.channelBasedDtoList.find((e) => e.channelName == "WhatsApp") != undefined && response.data.data.channelBasedDtoList.find((e) => e.channelName == "WhatsApp").conversion != "0")
                    conversionByChannelChartArray.push({ x: 'WhatsApp', y: parseFloat(response.data.data.channelBasedDtoList.find((e) => e.channelName == "WhatsApp").conversion.replace(',', '.')), color: colorArray[0] })
                if (response.data.data.channelBasedDtoList.find((e) => e.channelName == "Telegram") != undefined && response.data.data.channelBasedDtoList.find((e) => e.channelName == "Telegram").conversion != "0")
                    conversionByChannelChartArray.push({ x: 'Telegram', y: parseFloat(response.data.data.channelBasedDtoList.find((e) => e.channelName == "Telegram").conversion.replace(',', '.')), color: colorArray[1] })
                if (response.data.data.channelBasedDtoList.find((e) => e.channelName == "Instagram") != undefined && response.data.data.channelBasedDtoList.find((e) => e.channelName == "Instagram").conversion != "0")
                    conversionByChannelChartArray.push({ x: 'Instagram', y: parseFloat(response.data.data.channelBasedDtoList.find((e) => e.channelName == "Instagram").conversion.replace(',', '.')), color: colorArray[2] })
                if (response.data.data.channelBasedDtoList.find((e) => e.channelName == "Facebook") != undefined && response.data.data.channelBasedDtoList.find((e) => e.channelName == "Facebook").conversion != "0")
                    conversionByChannelChartArray.push({ x: 'Facebook', y: parseFloat(response.data.data.channelBasedDtoList.find((e) => e.channelName == "Facebook").conversion.replace(',', '.')), color: colorArray[3] })
                if (response.data.data.channelBasedDtoList.find((e) => e.channelName == "Twitter") != undefined && response.data.data.channelBasedDtoList.find((e) => e.channelName == "Twitter").conversion != "0")
                    conversionByChannelChartArray.push({ x: 'Twitter', y: parseFloat(response.data.data.channelBasedDtoList.find((e) => e.channelName == "Twitter").conversion.replace(',', '.')), color: colorArray[0] })
                if (response.data.data.channelBasedDtoList.find((e) => e.channelName == "Manuel") != undefined && response.data.data.channelBasedDtoList.find((e) => e.channelName == "Manuel").conversion != "0")
                    conversionByChannelChartArray.push({ x: 'Manuel', y: parseFloat(response.data.data.channelBasedDtoList.find((e) => e.channelName == "Manuel").conversion.replace(',', '.')), color: colorArray[1] })
                if (response.data.data.channelBasedDtoList.find((e) => e.channelName == "Mail") != undefined && response.data.data.channelBasedDtoList.find((e) => e.channelName == "Mail").conversion != "0")
                    conversionByChannelChartArray.push({ x: 'Mail', y: parseFloat(response.data.data.channelBasedDtoList.find((e) => e.channelName == "Mail").conversion.replace(',', '.')), color: colorArray[2] })
                setConversionByChannelChartData(conversionByChannelChartArray);

                let agentResponseTimeChartArray = [];
                for (var i = 0; i < response.data.data.agentBasedDtoList.length; i++) {
                    if (response.data.data.agentBasedDtoList[i].response != undefined && response.data.data.agentBasedDtoList[i].response != "0") {
                        agentResponseTimeChartArray.push({ x: response.data.data.agentBasedDtoList[i].agentFirstName + " " + response.data.data.agentBasedDtoList[i].agentLastName, y: parseFloat(response.data.data.agentBasedDtoList[i].response.replace(',', '.')), color: colorArray[i % 3] });
                    }
                }
                setAgentResponseTimeChartData(agentResponseTimeChartArray);
                let conversionByAgentChartArray = [];
                for (var i = 0; i < response.data.data.agentBasedDtoList.length; i++) {
                    if (response.data.data.agentBasedDtoList[i].conversion != undefined && response.data.data.agentBasedDtoList[i].conversion != "0") {
                        conversionByAgentChartArray.push({ x: response.data.data.agentBasedDtoList[i].agentFirstName + " " + response.data.data.agentBasedDtoList[i].agentLastName, y: parseFloat(response.data.data.agentBasedDtoList[i].conversion.replace(',', '.')), color: colorArray[i % 3] });
                    }
                }
                setConversionByAgentChartData(conversionByAgentChartArray);

                let revenueTargetRealizationChartArray = [];
                for (var i = 0; i < response.data.data.agentBasedDtoList.length; i++) {
                    if (response.data.data.agentBasedDtoList[i].revenueTargetRealization != undefined && response.data.data.agentBasedDtoList[i].revenueTargetRealization != "0") {
                        revenueTargetRealizationChartArray.push({ x: response.data.data.agentBasedDtoList[i].agentFirstName + " " + response.data.data.agentBasedDtoList[i].agentLastName, y: parseFloat(response.data.data.agentBasedDtoList[i].revenueTargetRealization.replace(',', '.')), color: colorArray[i % 3] });
                    }
                }
                setRevenueTargetRealizationChartData(revenueTargetRealizationChartArray);

                let productBasedChartArray = [];
                for (var i = 0; i < response.data.data.productBasedDtoList.length; i++) {
                    productBasedChartArray.push({ x: response.data.data.productBasedDtoList[i].leadProductName, y: response.data.data.productBasedDtoList[i].leadProductCount, color: colorArray[i % 3] });
                }
                setProductBasedChartData(productBasedChartArray);

                let lostDetailsByLeadCountChartArray = [];
                for (var i = 0; i < response.data.data.lostDetailByLeadCountBasedDtoList.length; i++) {
                    lostDetailsByLeadCountChartArray.push({ x: response.data.data.lostDetailByLeadCountBasedDtoList[i].lostDescription, y: response.data.data.lostDetailByLeadCountBasedDtoList[i].leadCount, color: colorArray[i % 3] });
                }
                setLostDetailsByLeadCount(lostDetailsByLeadCountChartArray);

                let outOfTopicDetailsByLeadCountChartArray = [];
                for (var i = 0; i < response.data.data.outOfTopicDetailByLeadCountBasedDtoList.length; i++) {
                    outOfTopicDetailsByLeadCountChartArray.push({ x: response.data.data.outOfTopicDetailByLeadCountBasedDtoList[i].outOfTopicDescription, y: response.data.data.outOfTopicDetailByLeadCountBasedDtoList[i].leadCount, color: colorArray[i % 3] });
                }
                setOutOfTopicDetailsByLeadCount(outOfTopicDetailsByLeadCountChartArray);

                let revenueByProductChartArray = [];
                for (var i = 0; i < response.data.data.revenueByProductBasedDtoList.length; i++) {
                    revenueByProductChartArray.push({ x: response.data.data.revenueByProductBasedDtoList[i].name, y: response.data.data.revenueByProductBasedDtoList[i].revenue, color: colorArray[i % 3] });
                }
                setRevenueByProduct(revenueByProductChartArray);

            } else {

            }
        })
            .catch(function (response) {
                //  props.setDialogOperationSoldFormOpen(false)
                if (response.data) {

                } else {

                }
            });
    }

    const FillAgentsSelectBox = () => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.REPORTSERVERAPI_URL + '/user/getagentlist',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setAgents(response.data);
            })
    }
    const FillChannelsSelectBox = () => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.REPORTSERVERAPI_URL + '/channels/returnchannelsselectbox',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setChannels(response.data.data);
            })
    }
    const FillCurrenciesSelectBox = () => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.REPORTSERVERAPI_URL + '/currency/getcurrenciesselectbox',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setCurrencies(response.data);
            })
    }
    const FillLeadProductsSelectBox = () => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.REPORTSERVERAPI_URL + '/leadproducts/ReturnLeadProductsSelectBox',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setLeadProducts(response.data.data);
            })
    }
    return (
        <>
            <Box component="form" style={{ background: "edf0f5" }} onSubmit={GetWholeReportSubmit}  >
                <div className='row mt-3  mb-3  CenterText  '>
                    <div className='col-md-2'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <label >Start Date</label> <br />
                            <DatePicker id="startDateId"
                                fullWidth
                                size="small"
                                value={selectedStartDate}
                                placeholder="" slotProps={{ textField: { size: 'small' } }} format="DD.MM.YYYY"
                                onChange={(e) => {
                                    var Date1 = formatDate(e.$d);
                                    setFilterReport(
                                        prev => ({ ...prev, startDateId: Date1 })
                                    );
                                    setSelectedStartDate(Date1);
                                }}
                                required />
                        </LocalizationProvider>
                    </div>
                    <div className='col-md-2'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <label>End Date</label> <br />
                            <DatePicker id="endDateId"
                                fullWidth
                                size="small"
                                value={selectedEndDate}
                                placeholder="" slotProps={{ textField: { size: 'small' } }} format="DD.MM.YYYY"
                                onChange={(e) => {
                                    var Date1 = formatDate(e.$d);
                                    setFilterReport(
                                        prev => ({ ...prev, endDateId: Date1 })
                                    );
                                    setSelectedEndDate(Date1);
                                }}
                                required />
                        </LocalizationProvider>
                    </div>
                    <div className='col-md-1'>
                        <label  > Currency  </label> <br />
                        <TextField
                            size="small"
                            id="currencyId" select
                            fullWidth
                            value={selectedCurrency}
                            onChange={(e) => {
                                setFilterReport(
                                    prev => ({ ...prev, currencyId: e.target.value })
                                )
                                setSelectedCurrency(e.target.value);
                            }}
                            required >
                            {currencies?.map((data) => (
                                <MenuItem key={data.id} value={data.id}>{data.symbol}</MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className='col-md-2'>
                        <label  >Agent(s) {selectedAgentCount} selected..</label> <br />
                        <TextField size="small" className='LeftText' id="agentIds"
                            SelectProps={{
                                multiple: true,
                            }}
                            select
                            disabled={agentIdsDisabled}
                            fullWidth
                            value={selectedAgentIds}
                            onChange={(e) => {
                                setFilterReport(
                                    prev => ({ ...prev, agentIds: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value })
                                )
                                setSelectedAgentCount(typeof e.target.value === 'string' ? e.target.value.split(',').length : e.target.value.length)

                                setSelectedAgentIds(
                                    typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                                );
                            }} >
                            {agents?.map((data) => (
                                <MenuItem key={data.userId} value={data.userId}>{data.firstName} {data.lastName}</MenuItem>
                            ))}
                        </TextField >
                    </div>
                    <div className='col-md-2'>
                        <label  >Channel(s) {selectedChannelCount} selected.. </label> <br />
                        <TextField size="small" className='LeftText' id="channelIds"
                            SelectProps={{
                                multiple: true
                            }}
                            select
                            fullWidth
                            value={selectedChannelIds}
                            onChange={(e) => {
                                setFilterReport(
                                    prev => ({ ...prev, channelIds: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value })
                                )
                                setSelectedChannelCount(typeof e.target.value === 'string' ? e.target.value.split(',').length : e.target.value.length)
                                setSelectedChannelIds(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                                );
                            }}>
                            {channels?.map((data) => (
                                <MenuItem key={data.text2} value={data.text2}>{data.text}</MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className='col-md-2'>
                        <label  >Product(s) {selectedLeadProductCount} selected.. </label> <br />
                        <TextField size="small" className='LeftText' id="leadProductIds"
                            SelectProps={{
                                multiple: true
                            }}
                            select
                            fullWidth
                            value={selectedLeadProductIds}
                            onChange={(e) => {
                                setFilterReport(
                                    prev => ({ ...prev, leadProductIds: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value })
                                )
                                setSelectedLeadProductCount(typeof e.target.value === 'string' ? e.target.value.split(',').length : e.target.value.length)
                                setSelectedLeadProductIds(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                                );
                            }}>
                            {leadproducts?.map((data) => (
                                <MenuItem key={data.id} value={data.id}>{data.text}</MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className='col-md-1'>
                        <ButtonGroup
                            disableElevation
                            variant="contained"
                            aria-label="Disabled elevation buttons"
                        >
                            <Button type="submit" variant="contained" startIcon={<SearchIcon style={{ marginLeft: "15px" }} />} style={{ marginTop: "27px", width: "5px", backgroundColor: "#2da8b7" }}>
                            </Button>
                            &nbsp;
                            <Button type="button" onClick={ResetReport} variant="contained" startIcon={<RestartAltIcon style={{ marginLeft: "15px", marginTop: "-5px" }} />} style={{ marginTop: "27px", width: "5px", backgroundColor: "#1f3960" }}>
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <div className='row  mt-5   '>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Leads" value={leadCount} TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Operations" value={operationCount} TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Target" value={targetCount} TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Target Realization" value={targetRealization} value2="%" TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Avg. Sales Price" value={averagePrice} value2={currencySymbol} TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Sales" value={salesCount} TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                </div>
                <div className='row mt-1 '>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Conversion" value={conversion} value2="%" TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Revenue (Operations)" value={revenue} value2={currencySymbol} TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Target Revenue" value={targetRevenue} value2={currencySymbol} TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Revenue Target Realization" value={revenueTargetRealization} value2="%" TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Avg. Response Time(Min.)" value={averageResponseTime} value2="" TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                    <div className='col-md-2 mt-2'>
                        <BoxWidget name="Sales (Amount)" value={salesAmount} value2={currencySymbol} TypographyClassName="WidgetHeaderDarkBlue" />
                    </div>
                </div>
                <div className='row mt-1 '>
                    <div className='col-md-4 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Revenue Target Realization' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={revenueTargetRealizationChartData} format="n2" />
                    </div>
                    <div className='col-md-8 mt-2'>
                        <Card className='CardStyle2'>
                            <CardContent >
                                <Typography variant="h6" component="div" className="WidgetHeaderDarkBlue">
                                    World Map
                                </Typography>
                                {showWorldMap ? <Button variant="outlined" onClick={WorldMapSwitchButtonOnClick} color="secondary" className='WorldMapSwitchButton'>List</Button> : null}
                                {showWorldMap ? null : <Button variant="outlined" onClick={WorldMapSwitchButtonOnClick} color="secondary" className='WorldMapSwitchButton'>Map</Button>}
                                {showWorldMap ?
                                    <Typography variant="body2" component="p" className="WidgetBodyNoMarginV2"   >
                                        <AmChartV4WorldMap datasource={worldMapChartData} getWholeReportSubmit={GetWholeReportSubmit} />
                                    </Typography>
                                    : null}
                                {showWorldMap ? null : <Typography variant="body2" component="p" className="WidgetBodyNoMargin"   >
                                    <TableComponent dataSource={worldMapChartData} />
                                </Typography>}
                            </CardContent>
                        </Card>

                    </div>
                </div>
                <div className='row  mt-1'>
                    <div className='col-md-4 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Conversion By Agent' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={conversionByAgentChartData} format="n2" />
                    </div>
                    <div className='col-md-4 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Agent Response Time' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={agentResponseTimeChartData} format="n2" />
                    </div>
                    <div className='col-md-4 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Conversion By Channel' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={conversionByChannelChartData} format="n2" />
                    </div>
                </div>
                <div className='row mt-3 mt-1'>
                    <div className='col-md-4 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Leads' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={leadCountChartData} format="n0" />
                    </div>
                    <div className='col-md-4 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Revenue (k)' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={salesRevenueChartData} format="n2" />
                    </div>
                    <div className='col-md-4 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Sales (Amount)' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={salesAmountChartData} format="n2" />
                    </div>
                </div>
                <div className='row mt-1 '>
                    <div className='col-md-6 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Number of Products' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={productBasedChartData} format="n0" />
                    </div>
                    <div className='col-md-6 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Status' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={statusChartData} format="n0" />
                    </div>
                </div>

                <div className='row mt-1 '>
                    <div className='col-md-6 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Revenue by Product' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={revenueByProduct} format="n0" />
                    </div>
                    <div className='col-md-6 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Lost Details' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={lostDetailsByLeadCount} format="n0" />
                    </div>
                </div>
                <div className='row mt-1 '>
                    <div className='col-md-6 mt-2'>
                        <ChartWidget cardStyle='CardStyle1' name='Out Of Topic Details' typographyHeaderClass='WidgetHeaderDarkBlue' typographyBodyClass='WidgetBodyNoMargin' dataSource={outOfTopicDetailsByLeadCount} format="n0" />
                    </div>
                </div>
            </Box >
        </>
    )
}

export default ReportsPage