import React from 'react'
import { Card, CardContent, Typography } from '@mui/material';
import { HorizontalBarChart } from './HorizontalBarChart';
import { VerticalBarChart } from './VerticalBarChart';
export const ChartWidget = (props) => {
    return (
        <>
            <Card className={props.cardStyle}>
                <CardContent >
                    <Typography variant="h6" component="div" className={props.typographyHeaderClass}>
                        {props.name}
                    </Typography>
                    <Typography variant="body2" component="p" className={props.typographyBodyClass}  >
                        <VerticalBarChart dataSource={props.dataSource} name={props.name} format={props.format} />
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}
