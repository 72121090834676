import React from 'react'
import { loadCldr } from '@syncfusion/ej2-base';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, DialogTitle, Grid } from '@mui/material';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, WorkWeek, Month, Agenda, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';
import { useRef } from 'react';
import axios from 'axios';

import SchedulerFilterForm from './SchedulerFilterForm';
import configData from "../../../src/config.json";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { SchedulerSetOperationSoldForm } from './SchedulerSetOperationSoldForm';
import { SchedulerSetOperationSoldEditForm } from './SchedulerSetOperationSoldEditForm';
loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/fr-CH/ca-gregorian.json'),
    require('cldr-data/main/fr-CH/numbers.json'),
    require('cldr-data/main/fr-CH/timeZoneNames.json')
);

export const Scheduler = () => {
    configureAbly({ key: configData.ABLY_KEY });
    const [dragDropOperationFail, setDragDropOperationFail] = useState(false)
    const [dialogContentText, setDialogContentText] = useState("");
    const [showUpdateButton, setShowUpdateButton] = useState('')
    const [OperationSoldTitle, setOperationSoldTitle] = useState('')
    const [OperationSoldEditTitle, setOperationSoldEditTitle] = useState('')
    const [ablyVerifiedIdentity, setAblyVerifiedIdentity] = useState('')
    const [channelleadtosoldforscheduler] = useChannel("LeadToSoldForScheduler", (message) => {
        var Identity = ReturnIdentity();
        var ablyReturnObject = JSON.parse(message.name);
        if (Identity.firmId == ablyReturnObject.firmId && Identity.userId == ablyReturnObject.userId) {

            setAblyVerifiedIdentity(JSON.parse(message.name));
            setOperationSoldTitle("Set Operation Sold: " + ablyReturnObject.title);
            RefreshScheduler();
        }
    });
    const [channelrefreshweasescheduler] = useChannel("RefreshWeaseScheduler", (message) => {
        var Identity = ReturnIdentity();
        var ablyReturnObject = JSON.parse(message.data);
        if (parseInt(Identity.firmId) == ablyReturnObject.firmId) {
            RefreshScheduler();
        }
    });

    const [channel2] = useChannel("LeadSold", (message) => {
        var Identity = ReturnIdentity();
        var ablyReturnObject = JSON.parse(message.data);
        if (Identity.firmId == ablyReturnObject.FirmId) {
            RefreshScheduler();
        }
    });

    const [schedulerSetOprSoldEditData, setSchedulerSetOprSoldEditData] = useState('');
    const [schedulerUpdateOperationDate, setSchedulerUpdateOperationDate] = useState('');
    const [dragDropTempData, setDragDropTempData] = useState('');
    const [schedulerCellDates, setSchedulerCellDates] = useState('');
    const childRef = useRef(null);
    const dispatch = useDispatch()
    const [handleOperationSoldFormOpen, setDialogOperationSoldFormOpen] = useState(false)
    const [handleOperationSoldEditFormOpen, setDialogOperationSoldEditFormOpen] = useState(false)
    const handleAddRecordBackdropClick = () => {
        return false;
    };
    const handleEditRecordBackdropClick = () => {
        return false;
    };
    const handleSetOperationDateFormClick = () => {
        return false;
    }

    const handleUpdateOperationDateFormClick = () => {
        return false;
    }
    const handleAddRecordDialogClose = (e) => {

    };
    const handleEditRecordDialogClose = (e) => {

    };

    const handleSetDialogFormClose = (e) => {

    }
    const handleUpdateDialogFormClose = (e) => {

    }
    const handleSetOperationSoldFormClose = (e) => {

    }
    const handleSetOperationSoldEditFormClose = (e) => {

    }
    const [leadProducts, setLeadProducts] = useState([]);
    const [leadProductsByCountryId, setLeadProductsByCountryId] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const PropertyPane = (props) => <div className="mt-5">{props.children}</div>;
    const formatDate = (d) => {
        var dd = d.getDate();
        var mm = d.getMonth() + 1;
        var yyyy = d.getFullYear();
        if (dd < 10) { dd = '0' + dd }
        if (mm < 10) { mm = '0' + mm };
        return d = yyyy + '-' + mm + '-' + dd;
    }

    const formatDate2 = (d) => {
        var dd = d.getDate();
        var mm = d.getMonth() + 1;
        var yyyy = d.getFullYear();
        var HH = d.getHours();
        var min = d.getMinutes();
        if (dd < 10) { dd = '0' + dd }
        if (mm < 10) { mm = '0' + mm };
        return d = yyyy + '-' + mm + '-' + dd + ' ' + HH + ':' + min;
    }
    const ReturnIdentity = () => {
        var Token = "";
        var FirmId = "";
        var UserId = "";
        var OperationClaimId = "";
        //   var Token = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI1IiwidW5pcXVlX25hbWUiOiJCdXJjdSBCYWxjxLEiLCJyb2xlIjoiQWdlbnQiLCJuYmYiOjE2OTE4NzkwMDMsImV4cCI6MTY5MjMxMTAwMywiaWF0IjoxNjkxODc5MDAzfQ.O7Emc5vv5lkLtUYKeQpdWVTE1IvJhCJXx5DMlbtN7Xa6iGT_2IFRTzm4CToXJxp6HkHUrafxUe_XeRyo_AkWCw";
        // var FirmId = 1;
        // var UserId = 5;
        var url = window.location.search.substring(1); //get rid of "?" in querystring
        var qArray = url.split('&'); //get key-value pairs
        for (var i = 0; i < qArray.length; i++) {
            var pArr = qArray[i].split('='); //split key and value
            if (pArr[0] == "token")
                Token = pArr[1]; //return value
            if (pArr[0] == "firmId")
                FirmId = pArr[1]; //return value
            if (pArr[0] == "userId")
                UserId = pArr[1]; //return value
            if (pArr[0] == "operationClaimId")
                OperationClaimId = pArr[1]; //return value
        }
        var Identity = {
            token: Token,
            firmId: FirmId,
            userId: UserId,
            operationClaimId: OperationClaimId
        };
        return Identity;
    }

    const FillLeadProducts = () => {
        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.SCHEDULERSERVERAPI_URL + '/leadproducts/ReturnLeadProductsSelectBox',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setLeadProducts(response.data.data);
            })
    }

    const FillLeadProductsByCountryId = async (countryIdParam) => {
        const Token = ReturnIdentity().token;
        var countryId = countryIdParam;
        if ((countryId == undefined || countryId == null) && ablyVerifiedIdentity != "" && ablyVerifiedIdentity != undefined && ablyVerifiedIdentity != null) {
            countryId = ablyVerifiedIdentity.countryId;
        }
        if (countryId == undefined || countryId == null) {
            countryId = "0";
        }
        const instance = await axios.create({
            baseURL: configData.SCHEDULERSERVERAPI_URL + '/leadproducts/ReturnLeadProductsForSalesOperationDetails/' + countryId,
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setLeadProductsByCountryId(response.data.data.products);
                if (countryIdParam == undefined || countryIdParam == null) {
                    setDialogOperationSoldFormOpen(true);
                } else {
                    setDialogOperationSoldEditFormOpen(true);
                }

            }).catch(function (error) {
                setDialogOperationSoldFormOpen(false);
                setDialogOperationSoldEditFormOpen(false);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }


    const FillCurrency = () => {

        const Token = ReturnIdentity().token;
        const instance = axios.create({
            baseURL: configData.SCHEDULERSERVERAPI_URL + '/Currency',
            headers: { 'Authorization': 'Bearer ' + Token }
        });
        instance.get('')
            .then(response => {
                setCurrencies(response.data);
            })
    }

    const updateFilters = () => {
        childRef.current.CallFillScheduler();
    };
    const onDragStart = (arg) => {
        setDragDropTempData(arg.data);
        arg.navigation.enable = true;
    };



    ///  let queryString = '&Statusesstr=' + JSON.stringify(Statuses);
    const onDataBinding = (e) => {
        let items = e.result;
        let scheduleData = [];
        if (items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                scheduleData.push({
                    Id: items[i].id,
                    Subject: items[i].subject,
                    StartTime: items[i].startTime,
                    EndTime: items[i].endTime,
                    IsAllDay: !items[i].startTime,
                    CategoryColor: items[i].categoryColor
                });
            }
        }
        e.result = scheduleData;
    }


    const onCreated = () => {
        childRef.current.CallFillScheduler();
    }
    const onDestroyed = () => {

    }
    const change = (args) => {
        childRef.current.CallFillScheduler();
    };

    const Schedule_ViewChanged = (e) => {

    }
    let _scheduleData = [];
    const [filters, setFilters] = useState(_scheduleData);
    const [data, setScheduleData] = useState(filters);
    const eventSettings = { dataSource: data, enableTooltip: true };
    const onEventRendered = (args) => {
        args.element.style.backgroundColor = args.data.CategoryColor;
    }
    useEffect(() => {
        FillLeadProducts();
        FillCurrency();
    }, []);

    const onActionBegin = (e) => {
        switch (e.requestType) {
            case "dateNavigate":
                setTimeout(function () {
                    childRef.current.CallFillScheduler();
                }, 500);
                break;
            case "eventChange":
                break;
            case "viewNavigate":
                setTimeout(function () {
                    childRef.current.CallFillScheduler();
                }, 500);
                break;
        }
    }

    const RefreshScheduler = () => {
        childRef.current.CallFillScheduler();
    }

    const OnCellClick = (e) => {

        var DateNow = new Date();
        var DateNowFormatted = formatDate(DateNow);
        var StartDateFormatted = formatDate(e.startTime);

        if (e.startTime > DateNow || DateNowFormatted == StartDateFormatted) {
            var schedulerCelldates = {};
            schedulerCelldates.startCellTime = e.startTime;
            schedulerCelldates.endCellTime = e.endTime;
            setSchedulerCellDates(schedulerCelldates);
            FillLeadProductsByCountryId();
        }
    }
    const onDragStop = async (e) => {
        const req = {
            agentSoldPerformanceId: e.data.Id,
            dropStartDateStr: e.data.StartTime,
            dropEndDateStr: e.data.EndTime
        };
        const config = {
            headers: { Authorization: `Bearer ${ReturnIdentity().token}` }
        };
        axios.post(
            configData.SCHEDULERSERVERAPI_URL + '/calendar/dragdropcalendar/',
            req,
            config
        ).then(response => {
            if (response.data.success == false) {
                setDragDropOperationFail(true);
                setDialogContentText(response.data.message);
            } else {
                RefreshScheduler();
            }
        }).catch(exception => {
            debugger;
        });
    }

    const onPopupOpen = async (e) => {
        e.cancel = true;

        if (e.data.Id != null && e.data.Id != undefined) {

            const Token = ReturnIdentity().token;
            const instance = await axios.create({
                baseURL: configData.SCHEDULERSERVERAPI_URL + '/leaddetails/getleaddetailforscheduler/' + e.data.Id,
                headers: { 'Authorization': 'Bearer ' + Token }
            });
            instance.get('')
                .then(response => {
                    if (response.data.data != null && response.data.data != undefined) {
                        setSchedulerSetOprSoldEditData(response.data.data);
                        if (ReturnIdentity().userId == response.data.data.sellerId || ReturnIdentity().operationClaimId == 2) {
                            setShowUpdateButton(true);
                        } else {
                            setShowUpdateButton(false);
                        }
                        setOperationSoldEditTitle("Sold Operation Update: " + response.data.data.subject);
                        FillLeadProductsByCountryId(response.data.data.countryId);
                    }
                })
        }
    }

    return (
        <>
            <Dialog open={dragDropOperationFail} onClose={() => setDragDropOperationFail(false)}>
                <DialogTitle>Drag drop could not be completed..</DialogTitle>
                <DialogContent>
                    <DialogContentText> {dialogContentText} </DialogContentText>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => { setDragDropOperationFail(false) }}>Ok</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={handleOperationSoldFormOpen} onClose={handleSetOperationSoldFormClose}>
                <DialogTitle > {OperationSoldTitle} </DialogTitle>
                <DialogContent>
                    <SchedulerSetOperationSoldForm setAblyVerifiedIdentity={setAblyVerifiedIdentity} ablyVerifiedIdentity={ablyVerifiedIdentity} leadProducts={leadProductsByCountryId} schedulerCellDates={schedulerCellDates} ReturnToken={ReturnIdentity().token} formatDate2={formatDate2} currencies={currencies} useSelector={useSelector} setDialogOperationSoldFormOpen={setDialogOperationSoldFormOpen} RefreshScheduler={RefreshScheduler} />
                </DialogContent>
            </Dialog>


            <Dialog open={handleOperationSoldEditFormOpen} onClose={handleSetOperationSoldEditFormClose}>
                <DialogTitle > {OperationSoldEditTitle} </DialogTitle>
                <DialogContent>
                    <SchedulerSetOperationSoldEditForm showUpdateButton={showUpdateButton} schedulerSetOprSoldEditData={schedulerSetOprSoldEditData} ablyVerifiedIdentity={ablyVerifiedIdentity} leadProducts={leadProductsByCountryId} schedulerCellDates={schedulerCellDates} ReturnToken={ReturnIdentity().token} formatDate2={formatDate2} currencies={currencies} useSelector={useSelector} setDialogOperationSoldEditFormOpen={setDialogOperationSoldEditFormOpen} RefreshScheduler={RefreshScheduler} />
                </DialogContent>
            </Dialog>

            <Grid container display="flex" spacing={1} >

                <Grid item={true} md={12}>
                    <SchedulerFilterForm ref={childRef} useSelector={useSelector} dispatch={dispatch} useState={useState} ReturnToken={ReturnIdentity().token} updateFilters={updateFilters} leadProducts={leadProducts} />
                </Grid >

                <Grid item={true} md={12}>
                    <ScheduleComponent id="WeaseSchedulerId" timeFormat="HH:mm" actionBegin={onActionBegin} firstDayOfWeek={1} popupOpen={onPopupOpen} cellClick={OnCellClick}
                        width='100%' height='550px' viewChanged="Schedule_ViewChanged" currentView='Month' eventSettings={eventSettings} created={onCreated} destroyed={onDestroyed} dataBinding={onDataBinding}
                        dragStart={onDragStart} dragStop={onDragStop} rowAutoHeight={true} eventRendered={onEventRendered}
                    >
                        <ViewsDirective>
                            {['Day', 'Week', 'WorkWeek', 'Month', 'Agenda'].map((item) => <ViewDirective key={item} option={item} />)}
                        </ViewsDirective>
                        <Inject services={[Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop]} />

                    </ScheduleComponent>
                </Grid >
            </Grid >
        </>
    )
}


