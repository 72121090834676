import React, { useState, useEffect } from 'react';
import { MenuItem, TextField, DialogActions, Grid, Button, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import configData from "../../../src/config.json";
import dayjs from 'dayjs';
import Select, { SelectChangeEvent } from '@mui/material/Select';
export const SchedulerSetOperationSoldForm = (props) => {
    const [operationSoldData, setOperationSoldData] = useState({})
    const [errorResult, setErrorResult] = useState('')
    const [errorResultStyle, setErrorResultStyle] = useState('')
    const checkOperationDate = (e) => {
        var operationdate = operationSoldData.operationDateRawId;
        if (operationdate == undefined) {
            return true;

        }

        // else {

        //     var OperationDateDay = operationSoldData.operationDateRawId.getDate();
        //     var OperationDateMonth = operationSoldData.operationDateRawId.getMonth() + 1;
        //     var OperationDateYear = operationSoldData.operationDateRawId.getFullYear();

        //     var OperationEndDateDay = e.$d.getDate();
        //     var OperationEndDateMonth = e.$d.getMonth() + 1;
        //     var OperationEndDateYear = e.$d.getFullYear();

        //     if (OperationDateYear > OperationEndDateYear) {
        //         return true;
        //     } else if (OperationDateYear == OperationEndDateYear && OperationDateMonth > OperationEndDateMonth) {
        //         return true;
        //     } else if (OperationDateYear == OperationEndDateYear && OperationDateMonth == OperationEndDateMonth && OperationDateDay > OperationEndDateDay) {
        //         return true;
        //     } else if (OperationDateYear == OperationEndDateYear && OperationDateMonth == OperationEndDateMonth && OperationDateDay == OperationEndDateDay) {
        //         return false;
        //     } else {
        //         return false;
        //     }
        // }
    }

    const dispatch = useDispatch()

    useEffect(() => {

        setOperationSoldData(
            prev => ({ ...prev, operationTypeId: (props.ablyVerifiedIdentity.selectedLeadProductId == null || props.ablyVerifiedIdentity.selectedLeadProductId == undefined) ? 0 : props.ablyVerifiedIdentity.selectedLeadProductId, operationDateId: props.formatDate2(props.schedulerCellDates.startCellTime), operationEndDateId: props.formatDate2(props.schedulerCellDates.endCellTime) })
        )
    }, []);


    const handleSetOperationSoldFormSubmit = (event) => {
        event.preventDefault();
        var token = props.ReturnToken;
        var leadId = props.ablyVerifiedIdentity.leadId;
        axios.post(configData.SCHEDULERSERVERAPI_URL + "/leaddetails/addsalesoperationsformobile", {
            LeadId: leadId,
            LeadProductId: operationSoldData.operationTypeId,
            CurrencyId: operationSoldData.currencyId,
            Deposit: operationSoldData.depositId,
            TotalPrice: operationSoldData.totalPriceId,
            OperationDate: operationSoldData.operationDateId,
            OperationEndDate: operationSoldData.operationEndDateId,
            OperationNotes: operationSoldData.operationNotesId,
            OperationExplain: operationSoldData.operationExplainId
        }, {
            headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
        }).then(function (response) {
            if (response.data.success == true) {
                props.setDialogOperationSoldFormOpen(false)
                setErrorResult("Success..");
                setErrorResultStyle("blue");
                props.setAblyVerifiedIdentity("");
            } else {
                setErrorResult(response.data.message);
                setErrorResultStyle("red");
            }
        })
            .catch(function (response) {
                //  props.setDialogOperationSoldFormOpen(false)
                if (response.data) {
                    setErrorResult(response.data.message);
                    setErrorResultStyle("red");
                } else {
                    setErrorResult(response.message);
                    setErrorResultStyle("red");
                }
            });

    };


    return (
        <Box component="form" onSubmit={handleSetOperationSoldFormSubmit}    >
            <div className='row'>
                <div className='col-4'>
                    <label className="LabelStyle">      Operation Type</label>
                </div>
                <div className='col-8'>
                    <TextField size="small" id="operationTypeId" select
                        fullWidth onChange={(e) => {
                            setOperationSoldData(
                                prev => ({ ...prev, operationTypeId: e.target.value })
                            )
                        }}
                        value={(operationSoldData.operationTypeId == "" || operationSoldData.operationTypeId == undefined || operationSoldData.operationTypeId == 0) ? 0 : operationSoldData.operationTypeId}
                        error={(operationSoldData.operationTypeId == "" || operationSoldData.operationTypeId == undefined || operationSoldData.operationTypeId == 0) ? true : false}
                        helperText={(operationSoldData.operationTypeId == "" || operationSoldData.operationTypeId == undefined || operationSoldData.operationTypeId == 0) ? "Please enter operation type.." : ""}
                        required >
                        {props.leadProducts?.map((data) => (
                            <MenuItem key={data.leadProductId} value={data.leadProductId}>{data.name} ({data.countryName}-{data.currencyName})</MenuItem>
                        ))}
                    </TextField>

                </div>
            </div>


            <div className='row  mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Operation Explain     </label>
                </div>
                <div className='col-8'>
                    <TextField id="operationExplainId"

                        placeholder="" fullWidth variant="outlined" size="small" onChange={(e) => {
                            setOperationSoldData(
                                prev => ({ ...prev, operationExplainId: e.target.value })
                            )
                        }} multiline rows={2} />
                </div>
            </div>

            <div className='row  mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Currency   </label>
                </div>
                <div className='col-8'>
                    <TextField
                        size="small"
                        error={operationSoldData.currencyId == undefined ? true : false}
                        helperText={operationSoldData.currencyId == undefined ? "Please enter currency.." : ""}
                        id="currencyId" select
                        fullWidth onChange={(e) => {
                            setOperationSoldData(
                                prev => ({ ...prev, currencyId: e.target.value })
                            )
                        }} required >
                        {props.currencies?.map((data) => (
                            <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
            <div className='row  mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Deposit   </label>
                </div>
                <div className='col-8'>
                    <TextField id="depositId"
                        onChange={e =>

                            setOperationSoldData(
                                prev => ({ ...prev, depositId: e.target.value })
                            )

                        }
                        error={operationSoldData.depositId == undefined ? true : false}
                        helperText={operationSoldData.depositId == undefined ? "Please enter deposit.." : ""}
                        placeholder="" type="number" fullWidth variant="outlined" size="small" required />
                </div>
            </div>

            <div className='row  mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Total Price   </label>
                </div>
                <div className='col-8'>
                    <TextField id="totalPriceId"

                        error={operationSoldData.totalPriceId == undefined ? true : false}
                        helperText={operationSoldData.totalPriceId == undefined ? "Please enter total price.." : ""}

                        placeholder="" type="number" fullWidth variant="outlined" size="small" onChange={(e) => {
                            setOperationSoldData(
                                prev => ({ ...prev, totalPriceId: e.target.value })
                            )
                        }} required />
                </div>
            </div>
            <div className='row  mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Operation Date    </label>
                </div>
                <div className='col-8'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker id="operationDateId"
                            disablePast="true"

                            placeholder="" fullWidth ampm={false} slotProps={{ textField: { size: 'small', required: true } }} format="DD.MM.YYYY HH:mm"
                            onChange={(e) => {
                                var Date1 = props.formatDate2(e.$d);
                                setOperationSoldData(
                                    prev => ({ ...prev, operationDateId: Date1, operationDateRawId: e.$d })
                                );
                            }} value={dayjs(props.schedulerCellDates.startCellTime)}
                            required />
                    </LocalizationProvider>
                </div>
            </div>

            <div className='row  mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Operation End Date     </label>
                </div>
                <div className='col-8'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker id="operationEndDateId"
                            disablePast="true"
                            shouldDisableDate={false}

                            placeholder="" fullWidth ampm={false} slotProps={{ textField: { size: 'small' } }} format="DD.MM.YYYY HH:mm"
                            onChange={(e) => {
                                var Date1 = props.formatDate2(e.$d);
                                setOperationSoldData(
                                    prev => ({ ...prev, operationEndDateId: Date1 })
                                );
                            }} value={dayjs(props.schedulerCellDates.endCellTime)}
                            required />
                    </LocalizationProvider>
                </div>
            </div>

            <div className='row  mt-2'>
                <div className='col-4'>
                    <label className="LabelStyle">  Operation Notes     </label>
                </div>
                <div className='col-8'>
                    <TextField id="operationNotesId"

                        error={operationSoldData.operationNotesId == undefined ? true : false}
                        helperText={operationSoldData.operationNotesId == undefined ? "Please enter some notes.." : ""}

                        placeholder="" fullWidth variant="outlined" size="small" onChange={(e) => {
                            setOperationSoldData(
                                prev => ({ ...prev, operationNotesId: e.target.value })
                            )
                        }} multiline rows={3} required />
                </div>
            </div>
            <div className='row  mt-2'>
                <div className='col-12'>
                    <label style={{ color: `${errorResultStyle}` }}>{errorResult}</label>
                </div>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <DialogActions>
                        <Button size="small" startIcon={<CancelIcon />} variant='contained' onClick={() => props.setDialogOperationSoldFormOpen(false)}
                            color="error">Cancel</Button>
                        <Button type="submit" size="small" startIcon={<SaveIcon />} variant='contained' color="success"  >Save</Button>
                    </DialogActions>
                </div>
            </div>
        </Box >
    )
}
