import Actions from "./actions";

const initState = {
  identity: "",
  isRoomHost: false,
  connectOnlyWithAudio: false,
  roomId: null,
  showOverlay: true,
  showOverlayVideoRecording: false,
  socketId: null,
  isMeetingStarted: false,
  firmName: "",
  leadFullName: ""
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case Actions.SET_IS_ROOM_HOST:
      return {
        ...state,
        isRoomHost: action.isRoomHost,
      };
    case Actions.SET_CONNECT_ONLY_WITH_AUDIO:
      return {
        ...state,
        connectOnlyWithAudio: action.onlyWithAudio,
      };
    case Actions.SET_ROOM_ID:
      return {
        ...state,
        roomId: action.roomId,
      };
    case Actions.SET_IDENTITY:
      return {
        ...state,
        identity: action.identity,
      };
    case Actions.SET_SHOW_OVERLAY:
      return {
        ...state,
        showOverlay: action.showOverlay,
      };
    case Actions.SET_SHOW_OVERLAY_VIDEO_RECORDING:
      return {
        ...state,
        showOverlayVideoRecording: action.showOverlayVideoRecording,
      };
    case Actions.SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.participants,
      };
    case Actions.SET_SOCKET_ID:
      return {
        ...state,
        socketId: action.socketId
      };
    case Actions.IS_MEETING_STARTED:
      return {
        ...state,
        isMeetingStarted: action.isMeetingStarted
      };

    case Actions.SET_FIRMNAME:
      return {
        ...state,
        firmName: action.firmName,
      };
    case Actions.SET_LEAD_FULLNAME:
      return {
        ...state,
        leadFullName: action.leadFullName,
      };
    default:
      return state;
  }
};

export default reducer;
